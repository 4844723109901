import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import UseStyles from "./style";
import logo from "../../../assets/Logo1.svg";
import { clearState } from "../../../actions/authAction";
import { useParams } from "react-router-dom";
import FilterBox from "./FilterBox";

const HeaderBar = (props) => {
  const { Navigation, Curated } = useSelector((state) => state);
  const classes = UseStyles();
  const dispatch = useDispatch();
  const { screen } = useParams();

  const handleLogout = () => {
    dispatch(clearState());
  };

  return (
    <>
      <div className={classes.headerBar}>
        <div className={classes.logoContainer}>
          <img src={logo} alt="Logo" />
        </div>
        {screen === "map viewer" || screen === "claims" ? <FilterBox classes={classes}/> : null}
        {/* <div className={classes.rightMenu}>
          <span onClick={handleLogout}>Logout</span>
        </div> */}
      </div>
    </>
  );
};
export default HeaderBar;
