import { makeStyles } from "@material-ui/core/styles";
import BackgroundImage from "../../../../assets/icons/arrow-down.png";

const useStyles = makeStyles((theme) => ({
  listViewBox: {
    maxHeight: '250px',
    width: '100%',
    maxWidth: '100vw',
    overflow: 'auto',
    '& div': {
      width: 'auto !important'
    },
    '& td': {
      '&:nth-child(1)': {
        width:'130px',
      },
      '&:nth-child(2)': {
        width:'150px',
      },
      '&:nth-child(5)': {
        width:'150px',
      },
      '&:nth-child(6)': {
        width:'350px',
      },
      '&:nth-child(7)': {
        width:'150px',
      },
    },
    '& table': {
      width: '100%'
    },
  },
  tableBody: {
    height: "50px",
    maxWidth: "100%",
    borderCollapse: "collapse",

    "&:nth-child(even)": {
      backgroundColor: "#F8F8F8",
    },
    "&:nth-child(odd)": {
      backgroundColor: "#FFFFFF",
    },

    "& td": {
      padding: "0 25px",
      width: "auto",
      "&:nth-child(1)": {
        fontWeight: "600",
        fontSize: "14px",
        color: "#0B94F8",
      },

      "&:nth-child(2)": {
        fontWeight: "600",
        fontSize: "14px",
        letterSpacing: "0.05em",
        color: "#454545",
      },
      "&:nth-child(3)": {
        fontWeight: "600",
        fontSize: "14px",
        color: "#707070",
      },
      "& img": {
        maxHeight: '45px'
      }
    },
    "& td:first-child": {
      color: "#0B94F8",
    },
  },
  adminUserStatus: {
    borderRadius: "18px",
    backgroundColor: "rgba(77, 147, 34, 0.1)",
    position: "relative",

    "& svg": {
      display: "none",
    },

    "&::before": {
      border: "0",
      content: "''",
      position: "absolute",
      width: "14px",
      height: "10px",
      top: "16px",
      left: "90px",
      background: `url(${BackgroundImage}) no-repeat`,
    },
    "&:hover:not(.Mui-disabled)::before": {
      border: "0px",
    },
    "&::after": {
      border: "0px",
    },
    "& .MuiSelect-select": {
      fontSize: "14px",
      fontWeight: "545",
      padding: "10px 35px 10px 25px",
    },
    "& .MuiSelect-select option": {
      backgroundColor: "#000000a8",
      color: "#fff !important",
    },

    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    active: {
      background: "green !important",
    },
    deactive: {
      backgroundColour: "red",

    }
  },
  updateLayerForm: {
    width: "60vw",
    height: "fit-content",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "20px",
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
  }
}));

export default useStyles;