import { red, deepOrange, brown } from '@mui/material/colors';
export const appDarkPalette = {
    primary: {
        main: '#2b2e64'
    },
    secondary: brown,
    error: {
        main: red.A400,
        800: red.A700
    },
    background: {
        default: '#fff'
    },
    grey: {
        300: '#494D5F',
        400: '#A8ACAA',
        mild: '#fffcfc'
    },
    info: {
        main: '#7575a3',
        100: '#e8f0fe'
    },
    warning: {
        main: deepOrange.A400
    }
};
export const appDarkColors = {
    grey: '#B8BECD',
    green: '#5EC994',
    white: '#fff',
    drawerText: '#7575a3',
    textLight: '#fff',
    darkest: '#fff',
    e4m: '#71eff9',
    background: '#141322',
    lightGrey: '#EDF2F5',
    lightest: '#fff',
    drawer: '#110e19',
    tableBg: '#26222a',
    tableBorder: 'transparent',
    paperBorder: '#232135',
    optionColor: '#353434cf',
    paperBoxShadow:
    '0 4px 10px 0 rgb(20 19 34 / 3%), 0 0 10px 0 rgb(20 19 34 / 2%)'
};
