const initialState = []

export default function (state = initialState, {type, payload}) {
  switch (type) {
    case "ADD_CLAIM_DETAILS":{
        return [payload];
    }
    default:
      return state;
  }
}