import { makeStyles } from "@material-ui/core/styles";
import { height } from "@mui/system";
import BackgroundImage from "../../../assets/icons/arrow-down.png";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "100%",
    position: "relative",
    paddingTop: "20px",
  
    "& table": {
      width: "100%",
      borderSpacing: "0",
      border: "1px solid #e6e6e9",
      borderRadius: "8px 8px 0 0",
      boxShadow: "1px 1px 10px 1px #c4c4c4",
     
    },
  },

  settings: {
    position: "absolute",
    right: "20px",
    '@media (max-width:1540px)' :{
      right: "75px",
  }
  },

  icon: {
    backgroundColor: "#303061",
    padding: "5px 10px",
    borderRadius: "0 0 10px 10px",

    "&:hover": {
      cursor: "pointer",
    },
  },

  tableHeader: {
    height: "50px",
    background:
      "transparent linear-gradient(180deg, #FFD7A8 0%, #FFB966 100%) 0% 0% no-repeat padding-box",
    borderCollapse: "collapse",

    "& th": {
      padding: "0 10px 0px 10px",
      textAlign: "left",
      fontSize: "14px",
      color: "#2f2f5d",
      userSelect: "none",

      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  tableHeaderContainer: {
    display: "flex",
    alignItems: "center",
    '& div': {
      display: 'flex',
    },
    textAlign: "center"
  },

  popoverBody: {
    width: "350px",
    padding: "20px 30px",
  },
  visibleDropdowns: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    marginBottom: "16px",
  },
  visibleHeader: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginBottom: "10px",
  },
  visibleHeaderText: {
    color: "#303061",
    fontSize: "18px",
    fontWeight: "600",
  },
  visibleSubHeaderText: {
    color: "#acacac",
  },
  deleteIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  visibleColumn: {
    height: "14px",
    padding: "12px 12px 12px 14px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    border: "1px solid #c4c4c4",
    borderRadius: "4px",
    fontWeight: "600",
  },
  newColumnBtn: {
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },

  claimIDText: {
    color: "#0B94F8",
    "&:hover": {
      cursor: "pointer",
    },
  },

  tableBody: {
    height: "50px",
    borderCollapse: "collapse",



    "&:nth-child(even)": {
      backgroundColor: "#F8F8F8",
    },
    "&:nth-child(odd)": {
      backgroundColor: "#FFFFFF",
    },

    "& td": {
      padding: "0 10px",
      "&:nth-child(1)": {
        fontWeight: "600",
        fontSize: "14px",
        color: "#0B94F8",
      
      },

      "&:nth-child(2)": {
        fontWeight: "600",
        fontSize: "14px",
        letterSpacing: "0.05em",
        color: "#454545",
      },
      "&:nth-child(3)": {
        fontWeight: "600",
        fontSize: "14px",
      },
      "&:nth-child(4)": {
        fontWeight: "600",
        fontSize: "14px",
      },
      "&:nth-child(5)": {
        fontWeight: "600",
        fontSize: "14px",
      },
      "&:nth-child(6)": {
        fontWeight: "600",
        fontSize: "14px",
      },

    },
    "& td:first-child": {
      color: "#0B94F8",
    },
  },
  tableBodyData: {
    // width: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#fff",
    padding: "20px",
    gap: "20px",
  
    '@media (max-width:1540px)' :{
        paddingRight:'7%',
    }
  },
  paginationBtns: {
    display: "flex",
    color: "#2F2F5D",
    fontWeight: "600",

    "&:hover": {
      cursor: "pointer",
    },
  },
  pagesText: {
    display: "flex",
    alignItems: "center",
    color: "#A0A0A0",
  },
  currentPage: {
    border: "1px solid #2F2F5D",
    padding: "4px 12px",
    borderRadius: "4px",
    backgroundColor: "#F0F0FD",
    marginRight: "10px",
    color: "#2F2F5D",
  },
  adminUserRole: {
    position: "relative",

    "&::before": {
      border: "0",
      content: "''",
      position: "absolute",
      width: "14px",
      height: "10px",
      top: "12px",
      left: "90px",
      background: `url(${BackgroundImage}) no-repeat`,

    },
    "&:hover:not(.Mui-disabled)::before": {
      border: "0px",
    },
    "&::after": {
      border: "0px",
    },
    "& .MuiSelect-select": {
      color: "#000",
      fontSize: "14px",
      fontWeight: "500",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select option": {
      backgroundColor: "#000000a8",
      color: "#fff !important",
    },
    "& svg": {
      display: "none",
    },
  },
  newColumnFooterBtn: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop:'10px',
    paddingBottom:'20px',
  },
  newColumnFooterBtnOk: {
    background: '#2F2F5D !important',
    color: '#fff !important',
    marginRight:'10px !important',
  },
  newColumnFooterBtnCancel:{
    border: '1px solid #2F2F5D !important',
    color: '#2F2F5D !important',
    marginRight:'10px !important',
  },
  newColumnFooterBtnClear:{
    border: '1px solid red !important',
    color: 'red !important',
  },

  adminUserStatus: {
    borderRadius: "18px",
    // backgroundColor: "rgba(77, 147, 34, 0.1)",
    position: "relative",

    "& svg": {
      display: "none",

    },

    "&::before": {
      border: "0",
      content: "''",
      position: "absolute",
      width: "14px",
      height: "10px",
      top: "16px",
      left: "90px",
      background: `url(${BackgroundImage}) no-repeat`,

    },
    "&:hover:not(.Mui-disabled)::before": {
      border: "0px",
    },
    "&::after": {
      border: "0px",
    },
    "& .MuiSelect-select": {
      fontSize: "14px",
      fontWeight: "545",
      padding: "10px 35px 10px 25px",
    },
    "& .MuiSelect-select option": {
      backgroundColor: "#000000a8",
      color: "#fff !important",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    active: {
      background: "green !important",
    },
    deactive: {
      backgroundColour: "red",

    },
  },
  ErrorTable:{
    background:'#fff',
    width:'1000px',
    padding:'10px 0',
    borderRadius:'6px',
    borderCollapse: 'collapse',
    "& tr": {
      borderBottom: "1px solid #ccc",
      padding:'10px',
      borderCollapse: 'collapse',
     },
    "& td": {
     borderBottom: "1px solid #ccc",
     padding:'10px 20px',
     borderCollapse: 'collapse',
     color:'#DE0000',
     fontSize:'16px',
    },
  },
  scrollTable:{
    '@media (max-width:1510px)' :{
      width:'100% !important',
      overflowX:'auto !important',
      "& table": {
       width:'100%',
       },
    },
  },

}));

export default useStyles;
