import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, IconButton, InputLabel, Select, TextField } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useDispatch, useSelector } from 'react-redux';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import BasicDateRangePicker from '../../molecules/dateRangePicker/dateRangeSelector';
import { paginationClasses } from '@mui/material';
import { useParams } from 'react-router-dom';

const FilterBox = ({ classes }) => {
    const { screen } = useParams();
    const { FilterReducer, MapFilterReducer } = useSelector((state) => state);
    const [filter, setFilter] = useState(screen === "claims" ? FilterReducer[0] :  MapFilterReducer[0]);
    const [location, setLocation] = useState('');
    const dispatch = useDispatch();

    const resetData = (field) => {
        const data = Object.keys(MapFilterReducer[0]).filter((key) => !field.includes(key)).reduce((obj, key) => {
          obj[key] = MapFilterReducer[0][key];
          return obj;
        }, {});
        return data 
    }

    // useEffect(() => {
    //     const delayDebounceFn = setTimeout(() => {
    //         setFilter({ ...filter, keyword: location })
    //     }, 5000)
    //     return () => clearTimeout(delayDebounceFn)
    // }, [location])

    useEffect(() => {
        if(screen === "map viewer"){
            setFilter(MapFilterReducer[0])
        }
        if(screen === "claims"){
            setFilter(FilterReducer[0])
        }
    },[screen])

    useEffect(() => {
        if(screen === "claims"){
            setFilter(FilterReducer[0])
        }
    },[FilterReducer])

    useEffect(() => {
        if(screen === "claims"){
            if(filter !== FilterReducer[0]){
                const resetfilter = {
                    pageNumber : FilterReducer?.[0]?.pageNumber,
                    pageSize: FilterReducer?.[0]?.pageSize,
                }
                dispatch({
                    type: "ADD_FILTER",
                    payload: JSON.stringify(filter) === JSON.stringify(resetfilter) ? filter: {...FilterReducer[0],...filter},
                })
            }
        }
        if(screen === "map viewer"){
            if(filter !== MapFilterReducer[0]){
                const envelop = JSON.parse(window.localStorage.getItem('envelop')); 
                dispatch({
                    type: "ADD_MAP_FILTER",
                    payload: envelop ? {...filter,envelop:envelop} : filter,
                })
            }
        }
    }, [filter])

    const handleClear = () => {
        if(screen === "claims"){
            setFilter({
                pageNumber: FilterReducer?.[0]?.pageNumber || 1,
                pageSize: FilterReducer?.[0]?.pageSize || 10,
            });
        }
        if(screen === "map viewer"){
            window?.mapLayer?.popup.close();
            const envelop = JSON.parse(window.localStorage.getItem('envelop')); 
            const reset = resetData(['status','claimStartDate','claimEndDate','lossStartDate','lossEndDate','keyword']);
            setFilter(envelop ? {...reset,envelop} : {...reset})
        }
        setLocation("");
    }

    const convertDateFormat = (dateString) => {
        var date;
        if(dateString?.includes('23:59:59')){
            date = new Date(dateString);
            date = new Date(date.setDate(date.getDate() - 1))
        }
        else{
            date = new Date(dateString); 
        }
        return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        });
    };

    return (
        <Box className={classes.mapFilterBox}>
            <Box className={classes.searchBar}>
                <TextField
                    id="search-bar"
                    label="Location"
                    variant="outlined"
                    className={classes.adminFilterLocation}
                    onChange={(e) => setLocation(e.target.value)}
                    onKeyDown={(e) => {
                        if(e.key === 'Enter'){
                            setFilter({ ...filter, keyword: location })
                        }
                    }}
                    placeholder="Search Location"
                    value={location}
                    size="small"
                />
                <IconButton type="submit" aria-label="search" onClick={() => setFilter({ ...filter, keyword: location })}>
                    <SearchIcon className={classes.icon} />
                </IconButton>
            </Box>
            <Box className={classes.claimStatusDate}>
                <span className={classes.labelField}>Loss Date</span>
                <Button className={classes.clearBtn} id="lossDatePicker">
                    <CalendarMonthIcon/>
                    <span className={classes.dateRangeText}>
                        {filter?.lossStartDate && filter?.lossEndDate ? (`${convertDateFormat(filter?.lossStartDate)} - ${convertDateFormat(filter?.lossEndDate)}`) : ""}
                    </span>
                    <img src="../images/sidebararrowbtnopen.svg" className={classes.clearBtnArrow} />
                </Button>
                <BasicDateRangePicker filter={filter} setFilter={setFilter} dateType="lossDate" tag1="lossDatePicker" tag2="lossDatePickerContainer" />
            </Box>
            <Box className={classes.claimStatusDate}>
                <span className={classes.labelField}>Claim Date</span>
                <Button className={classes.clearBtn} id="claimDatePicker">
                    <CalendarMonthIcon/>
                    <span className={classes.dateRangeText}>
                        {filter?.claimStartDate && filter?.claimEndDate ? (`${convertDateFormat(filter?.claimStartDate)} - ${convertDateFormat(filter?.claimEndDate)}`) : ""}
                    </span>
                    <img src="../images/sidebararrowbtnopen.svg" className={classes.clearBtnArrow} />
                </Button>
                <BasicDateRangePicker filter={filter} setFilter={setFilter} dateType="claimRaisedDate" tag1="claimDatePicker" tag2="claimDatePickerContainer" />
            </Box>
            <Box className={classes.claimStatus}>
                <span  className={classes.labelField}>Claim Status</span>
                <FormControl variant="outlined" className={classes.adminUserSelectField}>
                    <Select
                        native
                        label="Claim Status"
                        inputProps={{
                            name: 'Claim Status',
                            id: 'outlined-age-native-simple',
                        }}
                        value={filter.status || ""}
                        onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                    >
                        <option aria-label="None" value="" />
                        <option value={"Pending"}>Pending</option>
                        <option value={"Settled"}>Settled</option>
                        <option value={"Invalid"}>Invalid</option>
                    </Select>
                </FormControl>
            </Box>
            <Button className={`${classes.clearBtn} ${classes.clearLastBtn}`} onClick={handleClear}>Clear</Button>
        </Box>
    )
}

export default FilterBox