import React, { useState, useEffect } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Popover,
  Menu,
  Box,
  Typography,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import UseStyles from "./style";
import moment from "moment";

import {
  flexRender,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@mui/material";
import { getClaimList, updateClaim } from "../../../services/apiCalls/claimApi";
import { useDispatch, useSelector } from "react-redux";
import { handleEndTime, handleStartTime } from "../../../helpers/dateTimeStamp";

const DetailTable = (props) => {
  const dispatch = useDispatch();
  const { FilterReducer } = useSelector((state) => state);
  const [data, setData] = React.useState(() => [...props.data]);
  const [filterTable, setFilterTable] = useState(FilterReducer[0]);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [errors, setErrors] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({
    value: false,
  });
  const [pagination, setPagination] = React.useState({
    pageSize: 12,
    pageIndex: 0,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popHover, setPopHover] = React.useState(null);
  const classes = UseStyles();

  const open = Boolean(anchorEl);
  const hover = Boolean(popHover)
  const id = open ? "simple-popover" : undefined;
  const hoverId = hover ? "simple-popover-hover" : undefined;
  const columns = props.columns;

  useEffect(() => {
    setData([...props.data]);
  },[props.data])

  const clearFilter = async() => {
    setFilterTable({
      pageNumber: FilterReducer?.[0]?.pageNumber || 1,
      pageSize: FilterReducer?.[0]?.pageSize || 10,
    });
    handleClose();
  }

  useEffect(() => {
    setFilterTable(FilterReducer[0])
  },[FilterReducer])

  useEffect(async() => { 
    if(filterTable !== undefined && JSON.stringify(filterTable) !== JSON.stringify(FilterReducer[0])){
      const resetfilter = {
        pageNumber : FilterReducer?.[0]?.pageNumber,
        pageSize: FilterReducer?.[0]?.pageSize,
      }
      dispatch({
        type: "ADD_FILTER",
        payload: JSON.stringify(filterTable) === JSON.stringify(resetfilter) ? filterTable : {...FilterReducer[0],...filterTable},
      }); 
    }
  },[filterTable])

  let table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      columnVisibility,
      pagination,
    },
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const [tableColumn, setTableColumn] = React.useState(table.getVisibleLeafColumns().map(item => item.id));

  const unvisibleColumnsList = () => {
    const columnList  = table.getAllColumns().map(item => item.id);
    const visibleColumns = table.getVisibleLeafColumns().map(item => item.id)
    const unvisibleList = columnList.filter(item => !visibleColumns.includes(item));
    return unvisibleList;
  }

  const [unvisibleColumn, setUnvisibleColumn] = React.useState(unvisibleColumnsList());

  const convertDateFormat = (dateString) => {
    let date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const handlePopoverOpen = (event,data) => {
    setErrors(data)
    setPopHover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopHover(null);
  };

  const handleClaimStatusUpdate = async(e,id) => {
    let requiredData = data.filter((item) => item.claimNumber === id);
    if(requiredData && requiredData.length !== 0) {
      const data = await updateClaim(requiredData[0].id,{...requiredData[0],claimStatus:e.target.value})
      //   document.getElementsByClassName(classes.adminUserStatus).value = e.target.value
    }
  }
   
  const statusOptions = [  "Pending" ,"Settled"  ,"Invalid" ]

  const loadCell = (cell) => {
    switch (cell.column.id) {
      case "claimNumber":
        return (
          <span
            className={classes.claimIDText}
            onClick={() => props.triggerShowDetail(cell.row.original)}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </span>
        );
      case "claimRaisedDate":
        return convertDateFormat(cell.getValue());
      case "lossDate":
        return convertDateFormat(cell.getValue());
      case "error":
        return (
          <>
            <Typography
              aria-describedby={hoverId}
              onClick={(e) => handlePopoverOpen(e,cell.row.original)}
              style={{cursor:"pointer", color:'red',}}
            >
              {cell.getValue()}
            </Typography>
            <Popover
              id={hoverId}
              open={hover}
              onClose={handlePopoverClose}
              anchorEl={popHover}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'top',
              }}
            >
              <table className={classes.ErrorTable}>
                {
                  errors.claimNumber === "" &&
                  <tr>
                    <td>Error : </td>
                    <td>Claim Number</td>
                    <td>Not mentioned</td>    
                  </tr>
                }
                {
                  errors.policyNumber === "" &&  
                  <tr>
                    <td>Error : </td>
                    <td>Policy Number</td>
                    <td>Not mentioned</td>
                  </tr>
                }
                {
                  errors.location === "" &&  
                  <tr>
                    <td>Error : </td>
                    <td>GEO Location</td>
                    <td>Not mentioned</td>
                  </tr>
                }
              </table>
              {/* {cell.row.original.map((item,index) => {
                 
              })} */}
            </Popover> 
          </>  
        )
      case "role":
        return (
          <Select
            native
            label="Role"
            className={classes.adminUserRole}
            inputProps={{
              name: "Role",
              id: "outlined-age-native-simple",
              value: cell.getValue(),
            }}
          >
            <option value={"Admin"}>Admin</option>
            <option value={"IAG User"}>IAG User</option>
            <option value={"Tonkin User"}>Tonkin User</option>
          </Select>
        );
      case ("claimStatus" || "status"):
        return (
          <Select
            native
            label="Status"
            className={`${classes.adminUserStatus}  ${cell.getValue()}`}
            onChange={(e) => handleClaimStatusUpdate(e,cell.row.original.claimNumber)}
            inputProps={{
              name: "Status",
              id: "outlined-age-native-simple",
              defaultValue: cell.getValue(),
            }}
          >
           <option value={cell.getValue()}>{cell.getValue()}</option>
            {
               statusOptions.map((item,index)=>{
                  if(item !== cell.getValue()){
                    return <option key={index} value={item} >{item}</option>
                  }
             })
            }  
          </Select>
        );
      default:
        return (
          <div className={classes.tableBodyData}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        );
    }
  };

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColumns = (event, column) => {
      const visible = [...tableColumn.filter(item => item !== column),event.target.value];
      const unvisible = [...unvisibleColumn.filter(item => item !== event.target.value),column];   
      setTableColumn(visible);
      setUnvisibleColumn(unvisible);
  }

  const hideColumn = (column) => {
    const visible = tableColumn.filter(item => item !== column);   
    setTableColumn(visible);
    setUnvisibleColumn([...unvisibleColumn,column]);
  }

  const addColumn = () => {  
    if(unvisibleColumn.length > 0){
      setTableColumn([...tableColumn,unvisibleColumn[0]]);
      setUnvisibleColumn(unvisibleColumn.filter(item => item !== unvisibleColumn[0]));
    }
  }

  const cancelTableChanges = () => {
    setTableColumn(table.getVisibleLeafColumns().map(item => item.id));
    setUnvisibleColumn(unvisibleColumnsList());
    handleClose();
  }

  const handleColumnVisibilty = () => {
    tableColumn.map((item) => {
      setColumnVisibility((current) => {
        const { [item]: val, ...rest } = current;
        unvisibleColumn.map((col) => {
          rest[col] = false;
        })
        return rest;
      });
    })
    handleClose();
  }

  // const addNewColumn = () => {
  //   const key = Object.keys(columnVisibility)[0];
  //   setColumnVisibility((current) => {
  //     const { [key]: val, ...rest } = current;
  //     return rest;
  //   });
  // };

  // const updateVisibleColumns = (event, column) => {
  //   setColumnVisibility((current) => {
  //     const { [event.target.value]: val, ...rest } = current;
  //     rest[column.id] = false;
  //     return rest;
  //   });
  // };

  // const handlePageChange = (func) => {
  //   if (func === "next") {
  //     if (pagination.pageIndex + 1 < pagination.totalPages) {
  //       if(props?.details){
  //         setFilterTable({
  //           ...filterTable,
  //           pageNumber: Number(filterTable?.pageNumber) + 1
  //         })
  //       }
  //       setPagination((current) => {
  //         let { pageIndex, pageSize, totalPages } = current;
  //         pageIndex += 1;
  //         return { pageIndex, pageSize, totalPages };
  //       });
  //     }
  //   } else {
  //     if (pagination.pageIndex - 1 >= 0) {
  //       if(props?.details){
  //         setFilterTable({
  //           ...filterTable,
  //           pageNumber: Number(filterTable?.pageNumber) - 1
  //         })
  //       }
  //       setPagination((current) => {
  //         let { pageIndex, pageSize, totalPages } = current;
  //         pageIndex -= 1;
  //         return { pageIndex, pageSize, totalPages };
  //       });
  //     }
  //   }
  // };

  const handlePageChange = (func) => {
    if (func === "next") {
      if(props?.details?.hasNextPage){
        setFilterTable({
          ...filterTable,
          pageNumber: Number(filterTable?.pageNumber) + 1
        })
      }
    } else {
        if(props?.details?.hasPreviousPage){
          setFilterTable({
            ...filterTable,
            pageNumber: Number(filterTable?.pageNumber) - 1
          })
        }
      }
  };

  const handleSorting = (val) => {
    const columnToSort = val.id
    if(filterTable?.orderBy !== undefined){
      if(!filterTable?.orderBy.includes(columnToSort)){
        setFilterTable({
          ...filterTable,
          orderBy:[
            ...filterTable?.orderBy,
            columnToSort
          ]
        })
      } 
      else{
        const resetFilter = filterTable?.orderBy.filter(item => item !== columnToSort);
        if(resetFilter.length > 0){
          setFilterTable({
            ...filterTable,
            orderBy:[
              ...resetFilter
            ]
          })
        }
        else{
          const newFilter = Object.keys(filterTable).filter(key =>
            key !== 'orderBy').reduce((obj, key) =>
            {
                obj[key] = filterTable[key];
                return obj;
            }, {}
          );
          setFilterTable(newFilter)
        }
      }
    }
    else{
      setFilterTable({
        ...filterTable,
        orderBy:[columnToSort]
      })
    }
  }

  return (
    <div className={classes.tableContainer}>
      {props.page !== "uploadFile" && <div className={classes.settings}>
        <div
          aria-describedby={id}
          onClick={handlePopoverClick}
          className={classes.icon}
        >
          <SettingsIcon style={{ color: "white" }} />
        </div>
      </div>}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={classes.popoverBody}>
          <div className={classes.visibleHeader}>
            <span className={classes.visibleHeaderText}>Column options</span>
            <span className={classes.visibleSubHeaderText}>
              Add or remove columns
            </span>
          </div>
          {tableColumn.map((column, index) => {
            return (
              <div className={classes.visibleDropdowns} key={column}>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      SelectDisplayProps={{
                        style: { paddingTop: 12, paddingBottom: 12 },
                      }}
                      value={column}
                      onChange={(e) => handleColumns(e, column)}
                    >
                      <MenuItem key={index} value={column}>
                        {column}
                      </MenuItem>
                      {unvisibleColumn.map((col, childIndex) => {
                          return (
                            <MenuItem key={childIndex} value={col}>
                              {col}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                <div
                  className={classes.deleteIcon}
                  onClick={() => hideColumn(column)}
                >
                  <DeleteOutlinedIcon />
                </div>
              </div>
            );
          })}
          <div className={classes.newColumnBtn} onClick={addColumn}>
            <AddIcon /> Add new column
          </div>
          <Box className={classes.newColumnFooterBtn}>
          <Button onClick={handleColumnVisibilty} className={classes.newColumnFooterBtnOk}>Ok</Button>
          <Button onClick={cancelTableChanges} className={classes.newColumnFooterBtnCancel}>Cancel</Button>
          <Button onClick={clearFilter} className={classes.newColumnFooterBtnClear}>Clear</Button>
          </Box>
         
        </div>
      </Popover>

<div className={classes.scrollTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className={classes.tableHeader} key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.column.id === 'reports' || header.isPlaceholder ? null : (
                    <div className={classes.tableHeaderContainer}>
                      <div
                        onClick={() => handleSorting(header.column)}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                        )}
                        {FilterReducer?.[0]?.orderBy?.includes(header.column.id) && <FilterAltIcon/>}
                      </div>
                      {header.column.getCanFilter() ? (
                        <Filter column={header.column} table={table} filterTable={filterTable} setFilterTable={setFilterTable}/>
                      ) : null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr className={classes.tableBody} key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{loadCell(cell)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      </div>
        <div className={classes.paginationContainer}>
          <div
            className={classes.paginationBtns}
            onClick={() => handlePageChange("prev")}
          >
            <ChevronLeftIcon /> PREVIOUS
          </div>
          <div className={classes.pagesText}>
            <div className={classes.currentPage}>
              {props?.details?.currentPage}{" "}
            </div>
            out of {props?.details?.totalPages}
          </div>
          <div
            className={classes.paginationBtns}
            onClick={() => handlePageChange("next")}
          >
            NEXT <ChevronRightIcon />
          </div>
        </div> 
    </div>
  );
};

function Filter({ column, table, filterTable, setFilterTable }) {
  const columnFilterValue = column.getFilterValue();
  const dispatch = useDispatch();
  const dateFilter = [
    { name: "Today", filter: "today" },
    { name: "Last 30 Days", filter: "30_days" },
    { name: "This Month", filter: "this_month" },
    { name: "Last Month", filter: "last_month" },
    { name: "3 Calendar Months", filter: "last_3_months" },
  ];
  
  const sortedUniqueValues = React.useMemo(() =>
    column.id === "claimType"
      ? Array.from(column.getFacetedUniqueValues().keys()).sort()
      : dateFilter
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const noOfDayCountForInvoiceFilter = {
    lastSevenDays: 6,
    lastThirtyDays: 29
  }
  // const handleClose = (val) => {
  //   if (val) {
  //     column.setFilterValue(val);
  //   }
  //   setAnchorEl(null);
  // };

  const TypeOfDate = (type) => {
    let array = [];
    if(type === "claimRaisedDate"){
      array = ["claimStartDate", "claimEndDate"]
    }
    if(type === "lossDate"){
      array = ["lossStartDate", "lossEndDate"]
    }
    return array
  }

  const resetData = (field) => {
    const data = Object.keys(filterTable).filter((key) => !field.includes(key)).reduce((obj, key) => {
      obj[key] = filterTable[key];
      return obj;
    }, {}); 
    return data 
  }

  const handleFilter = (value, column) => {
    if(column === "claimType" && value !== "reset"){
      setFilterTable({...filterTable,claimType: value})
      setAnchorEl(!anchorEl)
    }
    if(value !== "reset" && (column === "claimRaisedDate" || column === "lossDate")){
      var dateType = TypeOfDate(column);
      switch(value){
        case "today":{
          setFilterTable({
            ...filterTable,
            [dateType[0]]: handleStartTime(new Date()), 
            [dateType[1]]: handleEndTime(new Date())
          });
          break;
        }
        case "30_days":{
          setFilterTable({
            ...filterTable,
            [dateType[0]]: handleStartTime(new Date()),
            [dateType[1]]: handleEndTime(moment().subtract(noOfDayCountForInvoiceFilter.lastThirtyDays, 'd').toDate())
          });
          break;
        }
        case "this_month":{
          setFilterTable({
            ...filterTable,
            [dateType[0]]: handleStartTime(moment().startOf('month').toDate()), 
            [dateType[1]]: handleEndTime(moment().toDate()),
          });
          break;
        }
        case "last_month":{
          setFilterTable({
            ...filterTable,
            [dateType[0]]: handleStartTime(moment().subtract(1, 'months').startOf('month').toDate()), 
            [dateType[1]]: handleEndTime(moment().subtract(1, 'months').endOf('month').toDate())
          });
          break;
        }
        case "last_3_months":{
          setFilterTable({
            ...filterTable,
            [dateType[0]]: handleStartTime(moment().subtract(3, 'months').startOf('month').toDate()), 
            [dateType[1]]: handleEndTime(moment().subtract(1, 'months').endOf('month').toDate())
          });
          break;
        }
      }
      setAnchorEl(!anchorEl)
    }
    if(value === "reset"){
      switch(column){
        case "claimType":{ 
          const reset = resetData(['claimType']);
          dispatch({
            type: "ADD_FILTER",
            payload: reset
          })
          setFilterTable(reset);
          break;
        }
        case "claimRaisedDate":{
          const reset = resetData(['claimStartDate','claimEndDate']);
          dispatch({
            type: "ADD_FILTER",
            payload: reset
          })
          setFilterTable(reset);
          break;
        }
        case "lossDate":{
          const reset = resetData(['lossStartDate','lossEndDate']);
          dispatch({
            type: "ADD_FILTER",
            payload: reset
          })
          setFilterTable(reset);
          break;
        }
      }
      setAnchorEl(!anchorEl)
    }
  }

  return (
    <>
      <ArrowDropDownIcon
        aria-controls={open ? "basic-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          style: {
            maxHeight: 300,
            width: "20ch",
          },
        }}
      >
        {column.id === "claimType"
          ? sortedUniqueValues.map((value, index) => (
              <MenuItem key={index} onClick={() => handleFilter(value,column.id)}>
                {value}
              </MenuItem>
            ))
          : sortedUniqueValues.map((value, index) => (
              <MenuItem key={index} onClick={() => handleFilter(value.filter,column.id)}>
                {value.name}
              </MenuItem>
            ))}
          <MenuItem key="ResetFilter" onClick={() => handleFilter("reset",column.id)}>
            Reset
          </MenuItem>
      </Menu>
    </>
  );
}
export default DetailTable;
