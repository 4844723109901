import { createStore, applyMiddleware } from "redux";
import {
  setLocalStoreItem,
  getLocalStoreItem,
  getIsActiveUser,
  setIsActiveUser,
  isAuth
} from "./helpers/localStoreHandler";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

function saveToLocalStorage(state) {
  try {
    const serializeState = JSON.stringify(state);
    setLocalStoreItem("state", serializeState);
  } catch (e) {
    console.log(e);
  }
}

function getStateFromLocalStorage() {
  try {
    const serializeState = getLocalStoreItem("state");
    if (serializeState !== null) {
      return JSON.parse(serializeState);
    } else {
      return undefined;
    }
  } catch (e) {
    console.log(e);
  }
}

//check if state exist on browser refresh and hydrate state.
const existingState = getStateFromLocalStorage()
  ? getStateFromLocalStorage()
  : {};
const store = createStore(rootReducer, existingState, applyMiddleware(thunk));
//const store = createStore(rootReducer, applyMiddleware(thunk));
const allowed = ['Auth','Navigation']

store.subscribe(() =>{
  const storeData  = store.getState();
  const itemToSaveInLocal = Object.keys(storeData).filter(key => allowed.includes(key)).reduce((obj, key) => {
    obj[key] = storeData[key];
    return obj;
  }, {});
  saveToLocalStorage(itemToSaveInLocal)
});

export default store;
