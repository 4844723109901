import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../molecules/inputField/inputField";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  IconButton,
  Typography,
} from "@material-ui/core";
import UseStyles from "./style";
import { useDispatch } from "react-redux";
import { authState } from "../../../actions/authAction";
import Users from "../../../dummyUser";
import UserPool from "../../../UserPool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import MicrosoftIcon from "../../../assets/icons/microsoft.png";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ForgotPassword from "../../molecules/forgotPassword";
import { userDetails } from "../../../services/apiCalls/userApi";

const LoginForm = (props) => {
  const classes = UseStyles();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    emailId: "",
    password: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (ev) => {
    let { name, value } = ev.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = (ev) => {
    ev.preventDefault();
    setError("");
    let userId = "";
    const user = new CognitoUser({
      Username: formData.emailId,
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: formData.emailId,
      Password: formData.password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: async (data) => {
        const tokenData = {
          token: data.idToken.jwtToken,
          refreshToken: data.refreshToken.token,
        };
        window.localStorage.setItem("token", JSON.stringify(tokenData));
        let userDetailsResponse = await userDetails();
        userId = userDetailsResponse.userType.toLowerCase();
        let userConfig;
        switch (userId) {
          case "admin":
            userConfig = Users.ADMIN;
            break;
          case "agency":
            userConfig = Users.AGENCY;
            break;
          case "tonkin":
            userConfig = Users.TONKIN;
            break;
        }
        dispatch(authState(userConfig));
        window.location.reload();
      },
      onFailure: (err) => {
        setError(
          typeof err.message === "string" ? err.message : "Some error occured"
        );
      },
      newPasswordRequired: function (userAttributes) {
        delete userAttributes.email;
        user.completeNewPasswordChallenge("Qwerty@123", userAttributes, this);
      },
    });
  };

  const handleSSOLogin = () => {
    window.open(`${process.env.REACT_APP_REDIRECT_SSO_URL}`);
  };

  const handleNavigateSignUp = () => {
    navigate("/signup");
  };

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  return (
    <>
      <div className={classes.cardContainer}>
        {forgotPassword ? (
          <ForgotPassword />
        ) : (
          <Card>
            <div className={classes.heading}>Login to your Account</div>
            <InputField
              className={classes.inputField}
              value={formData.emailId}
              type="text"
              name="emailId"
              handleChange={handleInputChange}
              placeholder={"Login ID"}
            />
            <InputField
              value={formData.password}
              type="password"
              name="password"
              handleChange={handleInputChange}
              placeholder={"password"}
            >
              <VisibilityOffIcon />
              {/* ) : (
                  <VisibilityIcon /> */}
            </InputField>
            <div>
              <Button
                onClick={handleLogin}
                variant="contained"
                disableElevation
                size="large"
                className={`${classes.button} ${classes.login}`}
              >
                Login
              </Button>
              <div className={classes.errorMessage}>{error}</div>
            </div>

            <div className={classes.actions}>
              <div>
                <Checkbox size="small" className={classes.checkbox} />
                Keep me logged in
              </div>
              <div onClick={handleForgotPassword}>Forgotten your password?</div>
            </div>
            <h2 className={classes.lineBreaker}>
              <span>OR</span>
            </h2>
            <div className={`${classes.signup_Action}`}>
              <Button
                onClick={handleSSOLogin}
                variant="contained"
                disableElevation
                size="large"
                className={`${classes.social_login} ${classes.button}`}
              >
                <img
                  src={MicrosoftIcon}
                  alt="microsoft icon"
                  className={classes.imgIcon}
                ></img>
                Continue with Microsoft Account
              </Button>
            </div>
            <Box className={classes.loginFooter}>
              <Typography>
                Don't have an account?<Link to="/">Sign Up</Link>
              </Typography>
            </Box>
          </Card>
        )}
      </div>
    </>
  );
};

export default LoginForm;
