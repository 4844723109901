import React from 'react'
import UseStyles from "./style";
import logo from "../../assets/Logo1.svg";
import { Box, Button, Grid } from '@material-ui/core';
import { Typography } from '@mui/material';

const PageNotFound = () => {
    const classes = UseStyles();
    return (
        <>
            <Box className={classes.headerErrorBar}>
                <img src={logo} alt="Logo" />
            </Box>

            <Box className={classes.headerErrorBarInner}>
                <Grid container className={classes.headerErrorBarInnerContainer}>
                    <Grid  item xs={4}>
                        <Box  className={classes.headerErrorBarBgpadding}>

                       
                        <Box className={classes.headerErrorBarBg}>
                        <Box className={classes.ErrorBarBg}>
                        <Typography variant='h2'>OOPS...</Typography>
                        <Typography>We Cant'n seem to find the</Typography>
                        <Typography>page you are looking for</Typography>
                        <Button className={classes.headerErrorButton} variant="contained">
                            <p>Back to Home Page</p>
                            <label>Send Invite Link</label>
                        </Button>
                        </Box>
                        </Box>
                        </Box>
                   
                    </Grid>
                    <Grid item xs={8} className={classes.ErrorImgContainer}>
                       <img src="../images/error-img.svg"  className={classes.ErrorImg}/>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default PageNotFound