export const authState = (e) => (dispatch) => {
  dispatch({
    type: "LOGIN",
    payload: {
      user: e,
    },
  });
  return Promise.resolve(e);
};

export const clearState = () => (dispatch) => {
  dispatch({
    type: "LOGOUT",
    payload: {},
  });
  return Promise.resolve();
};
