import React, { useState } from "react";
import useStyles from "./style";
import PdfIcon from "../../../assets/icons/pdf_icon.svg";
import MultiplePdfIcon from "../../../assets/icons/multiple_pdf_icon.svg";
import { Button, Tooltip } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ModalComponent from "../modal/modal";
import { openPDFFile } from "../../../services/apiCalls/claimApi";
import { base64toUint8Array, downloadPdf } from "../../../helpers/fileDownloader";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0px 0px 12px #00000029",
  },
}));

const PdfActions = (props) => {
  const classes = useStyles();
  const [reports, setReports] = useState(props.reports);
  const [file, setFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleFileOpen = (id) => {
    setOpenModal(true);
    console.log(id,"jj");
    openPDFFile(id).then((res) => {
      if (!res.errors) {
        const data = base64toUint8Array(res)
        setFile(data);
      }
    });
  };

  return (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            <div className={classes.pdfContainer}>
              {reports?.map((report, index) => {
                return (
                  <div key={index} className={classes.reportContainer}>
                    <img src={PdfIcon} alt="pdfIcon" />
                    <span className={classes.reportName}>{report.name}</span>
                    <div className={classes.actions}>
                      <ArrowDownwardIcon 
                        className={classes.icon} 
                        onClick={() => downloadPdf(report.id)}
                      />
                      <VisibilityIcon
                        onClick={() => handleFileOpen(report.id)}
                        className={classes.icon}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        }
        placement="left"
        arrow
        interactive
      >
        <img src={reports.length > 1 ? MultiplePdfIcon : PdfIcon} alt="pdfIcon " className={classes.pdfIcon} />
      </HtmlTooltip>
      <ModalComponent
        show={openModal}
        data={file}
        label="CLAIM INSTRUCTIONS"
        closeModal={() => setOpenModal(!openModal)}
      />
    </>
  );
};
export default PdfActions;
