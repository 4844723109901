import React, { useState, useRef, useEffect } from "react";
import { Grid, Typography, useTheme, Button } from "@mui/material";
import clsx from "clsx";
import useStyles from "./style";
import PublishIcon from "@mui/icons-material/Publish";
import UploadContainer from "../../molecules/uploadBox";
import DetailTable from "../../organisms/detailTable/detailTable";
import {detailColumns} from "../../../dummyTableData";
import ViewClaimDetails from "../../organisms/viewClaimDetails";
import ProgressBar from "../../molecules/progressBar/progressBar";
import { getClaimList } from "../../../services/apiCalls/claimApi";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { exportClaimExcel } from "../../../helpers/fileDownloader";

const DetailUpload = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { FilterReducer } = useSelector((state) => state);
  const [uploadButton, setUploadButton] = useState(true);
  const [claimData, setClaimData] = useState(null);
  const [claimList, setClaimList] = useState([]);
  const fileRef = useRef(null);

  // useEffect(async() => {
  //     const data = await getClaimList({pageNumber: 1, pageSize: 10});
  //     setClaimList(data);
  //     dispatch({
  //       type: "ADD_CLAIM_DETAILS",
  //       payload: data,
  //     });
  // },[])

  useEffect(async() => {
    if(FilterReducer.length !== 0){
      const data = await getClaimList(FilterReducer[0]);
      setClaimList(data);
      dispatch({
        type: "ADD_CLAIM_DETAILS",
        payload: data,
      });
    }
  },[FilterReducer])

  const handleShowUploadContainer = () => {
    setUploadButton(false);
  };

  const triggerShowDetail = (data) => {
    setClaimData(data);
  };

  const handlePage = () => {
    setUploadButton(true);
    setClaimData(null);
  };

  return (
    <Grid container className={clsx(classes.upload_container, "hide-scroll")}>
      {claimData || !uploadButton ? (
        <div style={{ width: "100%" }}>
          <Button onClick={handlePage} className={classes.claimBack}>
            <span>Back</span>
          </Button>
        </div>
      ) : (
        <>
          {uploadButton && (
            <Box className={classes.userHeaderContainer}>
              <Button onClick={() => exportClaimExcel(FilterReducer[0])}> <img src="../images/excelicon.svg" /> Export Excel</Button>
              <Button
                onClick={handleShowUploadContainer}
                className={classes.upload_file_button}
                variant="outlined"
                startIcon={<PublishIcon />}
              >
                Upload File
              </Button>
            </Box>
            // <div className={classes.button_row}>
            //   <Button
            //     onClick={handleShowUploadContainer}
            //     className={classes.upload_file_button}
            //     variant="outlined"
            //     startIcon={<PublishIcon />}
            //   >
            //     Upload File
            //   </Button>
            // </div>
          )}
          {/* {!uploadButton && <UploadContainer upload={handleUploadFile} />} */}
        </>
      )}

      {uploadButton ? (
        !claimData ? (
          claimList?.data?.length >= 0 ? (
            <div className={classes.customTableParents}>
            <DetailTable
              data={claimList?.data}
              columns={detailColumns}
              triggerShowDetail={(data) => triggerShowDetail(data)}
              details={claimList}
            />
            </div>
            
          ) : (
            <ProgressBar />
          )
        ) : (
          <ViewClaimDetails data={claimData} />
        )
      ) : (
        <UploadContainer />
      )}
      {/* {!uploadButton && <UploadContainer upload={handleUploadFile} />}
      <DetailTable data={TableData} columns={columns} /> */}
    </Grid>
  );
};

export default DetailUpload;
