import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Button, IconButton, Typography, useTheme } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import useStyles from "./style";
import { formatBytes } from "../../../utils/utils";



const DropZone = ({handleSetFile}) => {
    const { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
        accept: {
            'text/csv': ['.csv']
          },maxFiles:1});
    const classes = useStyles();
    const theme = useTheme();

    const files = acceptedFiles.map((file) => (
            <div className={classes.fileDetailsWrapper} key={file.path}>
              <IconButton>
                   <img src="../images/csvfileicon.svg" className={classes.arrowButton} />
              </IconButton>
              <div className={classes.fileDetails}>
                <Typography
                  className={classes.file_heading}
                  variant="body1"
                >
                  File Name: {file.name}
                </Typography>
                <Typography
                  color={theme.mixins.textColor}
                  className={classes.warning}
                  variant="body1"
                >
                  Size: {formatBytes(file.size) || 'Not available'}
                </Typography>
              </div>
            </div>
    ));

    useEffect(()=>{
        handleSetFile(acceptedFiles)
    },[acceptedFiles]);
    
    return (
      <div {...getRootProps({ className: `dropzone ${classes.dropZone}` })}>
        <input className="input-zone" {...getInputProps()} />
        <div className={classes.upload_content}>
            <IconButton className={classes.upload_icon_box}>
                <UploadFileIcon className={classes.upload_icon} />
            </IconButton>
            <div className={classes.browseContent}>
                <div className={classes.attachment}>
                <Typography
                    variant="body1"
                    className={classes.attachmentContent}
                >
                    Drag and Drop file or
                </Typography>
                <Button
                    variant="contained"
                    component="label"
                    className={classes.uploadFileButton}
                    // onClick={open}
                >
                    browse
                </Button>
                </div>
                <Typography
                className={classes.warning}
                variant="body1"
                >
                Maximum file size: 10mb. Supported Formats: <span>CSV</span>
                </Typography>
            </div>
            {files}
        </div>
      </div>
    );
}

export default DropZone;