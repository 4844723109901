import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: "5px",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#c8c8c8",
            borderWidth: "1px",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#34346B !important",
            borderWidth: "1px !important",
        },
        
    },
    adminUserSelect: {
        display: "flex",
        justifyContent: "space-between",
        gap: "25px",
        paddingTop: "20px",
    },
    adminUserSelectField: {
        width: "32%",
          "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        //    fontSize:"12px",
          },
          "& .Mui-focused": {
            color:"#34346F",
           },
           "& .MuiSelect-outlined.MuiSelect-outlined": {
            color: "#000",
            fontSize: "16px",
            fontWeight: "normal",
          },
    },
    adminUserFormUrlMain: {
       "& #outlined-basic": {
            color: "#000",
            fontSize: "16px",
            fontWeight: "normal",
          },
    },
    adminUserFormUrl: {
        width: "100%",
        marginTop: "25px",
        marginBottom: "25px",

        "& .Mui-focused": {
         color:"#34346F",
        }
    },
    adminUserBtnMain: {
        marginTop:"25px",
    },
    adminUserBtn: {
        width: "100%",
        backgroundColor: "#34346F !important",
        maxWidth: "320px",
        color: "#fff !important",
        margin: "0 auto !important",
        fontSize: "20px !important",
        fontWeight: "400 !important",
        textTransform: "capitalize !important",
        borderRadius:"4px !important",
        border: "1px solid #34346F !important",

        "&:hover": {
            backgroundColor: "#fff !important",
            color: "#34346F !important",
            textTransform: "capitalize !important",
            border: "1px solid #34346F !important",
        },
    },
    authenticationType:{
        width: "100%",
        "& .Mui-focused": {
            color:"#34346F",
           },
    },
    adminUserPass:{
        "& .MuiFilledInput-root": {
            backgroundColor: "#f0f0f0",
            border: "1px solid #c8c8c8",
            borderRadius: "4px",
            "&:hover": {
                border: "1px solid #34346F",
            },
            "& .Mui-focused": {
                border: "1px solid #34346F",
            },
        },
        "& .MuiFilledInput-underline::before":{
            border:"0px",
        },
        "& .MuiFilledInput-underline::after": {
            border:"0px",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink":{
            background: "#f0f0f0",
            padding: "0px 8px",
        },
    },
}));

export default useStyles;
