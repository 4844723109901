const initialState = [{
  envelop:{
    minLatitude: -46.4286987596417, 
    maxLatitude: -35.610689515054524, 
    minLongitude: 156.82084082296174, 
    maxLongitude: -170.4508408229617
  }
}];

export default function (state = initialState, { type, payload = {} }) {
  switch (type) {
    case "ADD_MAP_FILTER":
      return [{...payload}];
    default:
      return state;
  }
}