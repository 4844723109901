const initialState = []

export default function (state = initialState, {type, payload}) {
  switch (type) {
    case "ADD_USER":{
        return [payload];
    }
    case "UPDATE_USER": {
        const user = state.filter(item => item.id !== payload[0].id);
        return [...user, ...payload];
    }
    case "REMOVE_USER":{
        const remainingUsers = state.filter(item => item.id !== payload[0].id);
        return remainingUsers;
    }
    default:
      return state;
  }
}