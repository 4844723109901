import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../../molecules/inputField/inputField";
import { Button, Card} from "@material-ui/core";
import UseStyles from "./style";
import { useDispatch } from "react-redux";
import UserPool from "../../../UserPool";
import { CognitoUser } from "amazon-cognito-identity-js"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const ForgotPassword = (props) => {
  const classes = UseStyles();
//   const dispatch = useDispatch();
  const [stage, setStage] = useState(1);
  const [emailId, setEmailId] = useState('');
  const [formData, setFormData] = useState({
    verificationCode: '',
    password: '',
    confirmPassword: ''
  });
  
  const navigate = useNavigate();

  const handleInputChange = (ev) =>{
    let {name, value} = ev.target;
    setFormData((prevState)=>  ({
      ...prevState,
      [name] : value
    }));
  }

  const getUser = () => {
    return new CognitoUser({
        Username: emailId,
        Pool:  UserPool,
    })
  }

  const sendCode = event => {
    event.preventDefault();

    getUser().forgotPassword({
        onSuccess:(data) => {
            console.log('Success', data)
        },
        onFailure: (err) => {
            console.error('Error', err)
        },
        inputVerificationCode: data => {
            console.log("Input code",data);
            setStage(2);
        }
    })
  }

  const handleResetPassword = event => {
    event.preventDefault();

    if(formData.password !== formData.confirmPassword){
        console.log('password dont match');
        return;
    }

    getUser().resetPassword(formData.verificationCode, formData.password, {
        onSuccess:(data) => {
            console.log('Success', data)
            navigate('/');
        },
        onFailure: (err) => {
            console.error('Error', err)
        },
    })
  }

  return (
    <>
      <div className={classes.cardContainer}>
        <Card>
          <div className={classes.heading}>ForgotPassword</div>
          {stage === 1 ? <>
            <InputField
                className={classes.inputField}
                value={emailId}
                type="text"
                name='emailId'
                handleChange= {(e)=> setEmailId(e.target.value)}
                placeholder={'Enter Email Id'}
            />
            <Button
                onClick={sendCode}
                variant="contained"
                disableElevation
                size="large"
                className={`${classes.button} ${classes.login}`}
            > Send Verification Code</Button>
        </>
             : <>
                <InputField
                    className={classes.inputField}
                    value={formData.verificationCode}
                    type="text"
                    name='verificationCode'
                    handleChange= {handleInputChange}
                    placeholder={'Enter Verification Code'}
                />
                <InputField
                    value={formData.password}
                    type="password"
                    name='password'
                    handleChange= {handleInputChange}
                    placeholder={'password'}
                >
                    <VisibilityOffIcon/>
                        {/* ) : (
                        <VisibilityIcon /> */}
                </InputField>
                <InputField
                    value={formData.confirmPassword}
                    type="password"
                    name='confirmPassword'
                    handleChange= {handleInputChange}
                    placeholder={'confirm password'}
                >
                    <VisibilityOffIcon/>
                        {/* ) : (
                        <VisibilityIcon /> */}
                </InputField>
                <Button
                    onClick={handleResetPassword}
                    variant="contained"
                    disableElevation
                    size="large"
                    className={`${classes.button} ${classes.login}`}
                >
                Change Password
              </Button>
            </>
}
        </Card>
      </div>
    </>
  );
};

export default ForgotPassword;
