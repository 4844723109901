import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import UseStyles from "./style";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import PdfIcon from "../../../assets/icons/pdf_icon.svg";
import ModalComponent from "../../molecules/modal/modal";
import InputField from "../../molecules/inputField/inputField";
import PublishIcon from "@mui/icons-material/Publish";
import ClaimStatus from "./ClaimStatus";
import {
  claimReportUploadFile,
  claimUploadFile,
} from "../../../services/apiCalls/claimApi";
import NoPdfIcon from "../../../assets/icons/no_pdf_icon.svg";

function getLabel(item) {
  let capitalizeString = item.charAt(0).toUpperCase() + item.slice(1);
  let splitArr = capitalizeString.split(/(?=[A-Z])/);
  let labelName = splitArr.join(" ");
  return labelName;
}

const ViewClaimDetails = (props) => {
  const classes = UseStyles();
  const [claimData, setClaimData] = useState(props?.data);
  const [reports, setReports] = useState(props?.data?.claimReports || []);
  const [fileURL, setFileURL] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [tempClaimData, setTempClaimData] = useState(props?.data);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [loader, setLoader] = useState({
    inspectionReport: false,
    claimReport: false,
    lossAdjusterReport: false,
  });

  const hiddenFileInput = React.useRef(null);

  const handleInputChange = (ev) => {
    let { name, value } = ev.target;
    setTempClaimData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUploadFile = (name) => {
    setSelectedFileName(name);
    hiddenFileInput.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    let body = {
      file: file,
      reportName: selectedFileName,
      claimId: claimData.id,
    };

    if (file !== []) {
      setLoader((prevState) => ({
        ...prevState,
        [selectedFileName]: true,
      }));

      const data = await claimReportUploadFile(body);

      const index = tempClaimData.claimReports?.findIndex(
        (el) => el.name === selectedFileName
      );

      if (index && index >= 0) {
        tempClaimData.claimReports[index].id = data.reportId;
      } else {
        tempClaimData.claimReports.push({
          name: selectedFileName,
          id: data.reportId,
          claimId: tempClaimData.id,
          url: "",
        });
      }

      setLoader((prevState) => ({
        ...prevState,
        [selectedFileName]: false,
      }));
    }
  };

  const renderEditInputField = (label, value) => {
    if (
      label === "uploadedReport" ||
      label === "claimReports" ||
      label === "abcReport"
    )
      return (
        <div className={classes.fileEditState}>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <img src={PdfIcon} alt="pdfIcon" className={classes.pdfIcon} />
          {loader ? (
            <>Uploading...</>
          ) : (
            <Button
              className={classes.uploadBtn}
              variant="outlined"
              startIcon={<PublishIcon />}
              onClick={handleUploadFile}
            >
              Upload File
            </Button>
          )}
        </div>
      );
    return typeof tempClaimData[label] === "object" ? null : (
      <div className={classes.inputEditState}>
        <InputField
          className={classes.inputField}
          value={tempClaimData[label]}
          type="text"
          name={label}
          handleChange={handleInputChange}
          placeholder={"Enter Value"}
        />
      </div>
    );
  };

  const handleFormCancel = () => {
    setEditForm(false);
  };

  const handleFormSubmit = () => {
    setClaimData(tempClaimData);
  };

  const handleEdit = () => {
    setEditForm(true);
  };

  const handleFileViewer = (label) => {
    setFileURL(claimData[label]);
    setOpenModal(true);
  };

  const checkLabelnValue = (label, value) => {
    if (
      label === "uploadedReport" ||
      label === "claimReport" ||
      label === "abcReport" ||
      label === "urgent"
    )
      return (
        <div
          className={`${classes.item1} ${
            label === "urgent" ? "importantClaim" : ""
          }`}
        >
          <img
            src={label === "urgent" ? "/images/flag.png" : PdfIcon}
            alt="pdfIcon  ssddsdsdasasdasdasdadasdas"
            onClick={() => handleFileViewer(label)}
            className={classes.pdfIcon}
          />
          {label === "urgent" && value ? "Urgent" : "Not Urgent"}
        </div>
      );
    else
      return (
        <div
          className={`${classes.item1} ${
            label === "claimType" ||
            label === "locationX" ||
            label === "locationY"
              ? "importantClaim"
              : ""
          }`}
        >
          {value || "N/A"}
        </div>
      );
  };
  return (
    <>
      <ClaimStatus />
      <Grid container className={classes.wrapper}>
        <ul className={classes.listContainer}>
          <div className={classes.detailsHeader}>
            <Typography
              variant="h1"
              align="center"
              className={classes.listHeading}
            >
              Detail
            </Typography>
            <ModeEditOutlinedIcon
              onClick={handleEdit}
              className={classes.editIcon}
            />
          </div>
          {Object.keys(claimData)?.map((el, index) => {
            return (
              typeof claimData[el] !== "object" && (
                <li key={index} className={classes.listItem}>
                  <div className={classes.item}>{getLabel(el)}</div>
                  {!editForm
                    ? checkLabelnValue(el, claimData[el])
                    : renderEditInputField(el, claimData[el])}
                </li>
              )
            );
          })}
          {!editForm &&
            reports.map((report, index) => {
              return (
                <li key={index} className={classes.listItem}>
                  <div className={classes.item}>{getLabel(report.name)}</div>
                  <div className={classes.item1}>
                    <img
                      src={PdfIcon}
                      alt="pdfIcon"
                      onClick={() => handleFileViewer("test")}
                      className={classes.pdfIcon}
                    />
                  </div>
                </li>
              );
            })}
          {editForm &&
            ["inspectionReport", "claimReport", "lossAdjusterReport"].map(
              (item, index) => {
                return (
                  <li key={index} className={classes.listItem}>
                    <div className={classes.item}>{getLabel(item)}</div>
                    <div className={classes.fileEditState}>
                      <input
                        name={item}
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                      {tempClaimData.claimReports?.findIndex(
                        (el) => el.name === item
                      ) >= 0 ? (
                        <img
                          src={PdfIcon}
                          alt="pdfIcon"
                          className={classes.pdfIcon}
                        />
                      ) : (
                        <img
                          src={NoPdfIcon}
                          alt="noPdfIcon"
                          className={classes.pdfIcon}
                        />
                      )}
                      {loader[item] ? (
                        <>Uploading...</>
                      ) : (
                        <Button
                          className={classes.uploadBtn}
                          variant="outlined"
                          startIcon={<PublishIcon />}
                          onClick={() => handleUploadFile(item)}
                        >
                          Upload File
                        </Button>
                      )}
                    </div>
                  </li>
                );
              }
            )}
        </ul>
      </Grid>
      {editForm && (
        <div className={classes.footer}>
          <Button
            className={classes.actionBtn}
            variant="outlined"
            onClick={handleFormCancel}
          >
            Cancel
          </Button>
          <Button
            className={classes.actionBtn}
            variant="outlined"
            onClick={handleFormSubmit}
          >
            Save
          </Button>
        </div>
      )}
      <ModalComponent
        show={openModal}
        data={fileURL}
        label="CLAIM INSTRUCTIONS"
        closeModal={() => setOpenModal(!openModal)}
      />
    </>
  );
};

export default ViewClaimDetails;
