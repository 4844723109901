import UserPool from "../UserPool";

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function checkRefereshToken() {
  return new Promise((resolve, reject) => {
    let cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }

        let refresh_token = session.getRefreshToken(); // receive session from calling cognitoUser.getSession()
        //   if (AWS.config.credentials && AWS.config.credentials.needsRefresh()) {
        cognitoUser.refreshSession(refresh_token, (err, session) => {
          if (err) {
            console.log(err);
          } else {
            //   console.log(session.getId)
            // AWS.config.credentials.params.Logins[
            //   `cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_USERPOOL_ID}`
            // ] = session.getIdToken().getJwtToken();
            const updatedToken = session.getIdToken().getJwtToken();
            let tokenObj = JSON.parse(window.localStorage.getItem("token"));
            tokenObj.token = updatedToken;
            window.localStorage.setItem("token", JSON.stringify(tokenObj));
            console.log("in promise");
            resolve(true);
            // AWS.config.credentials.refresh((err) => {
            //   if (err) {
            //     console.log(err);
            //   } else {
            //     console.log("TOKEN SUCCESSFULLY UPDATED");
            //     // JSON.stringlify(window.localStorage.setItem("token"));
            //   }
            // });
          }
        });
        //   }
      });
    }
  });
}
