import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    upload_box:  {
        display:'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: '18px'
    },
    upload_heading:  {
        color:  `${theme.mixins.purple}`,
        fontSize: '1.5rem !important'
    },
    upload_button: {
        height: '48px',
        width: '125px',
        borderRadius: '4px',
        background: `${theme.mixins.purple} !important`,
        color: 'white !important',
        cursor: 'pointer',
        textTransform: 'none !important',
        "&:disabled": {
            backgroundColor: `${theme.mixins.grey} !important`
        }
    },
}));

export default useStyles;
