import { toast } from "react-toastify";
import axios from "axios";
import { checkRefereshToken } from "../../utils/utils";

const url = `${process.env.REACT_APP_MAP_API_URL}`;

const token = async () => {
  await checkRefereshToken();
  const tokenData = JSON.parse(window.localStorage.getItem("token"));
  return {
    headers: {
      Authorization: `Bearer ${tokenData?.token}`,
    },
  };
};

const addNewMapLayer = async (data) => {
  const header = await token();
  axios
    .post(`${url}/v1/gislayers`, data, header)
    .then((res) => {
      if (res.status === 200) {
        toast.success("New map layer added successfully.");
      }
      if (res.status === 403) {
        // refreshToken()
        localStorage.clear();
      }
    })
    .catch((err) => {
      toast.error("Unable to add new map layer !!");
    });
};

const removeMapLayer = async (id) => {
  const header = await token();
  axios
    .delete(`${url}/v1/gislayers/${id}`, header)
    .then((res) => {
      if (res.status === 200) {
        toast.success("Map layer removed successfully.");
      }
      if (res.status === 403) {
        // refreshToken()
        localStorage.clear();
      }
    })
    .catch((err) => {
      toast.error("Unable to remove map layer !!");
    });
};

const updateMapLayer = async (id, data) => {
  const header = await token();
  axios
    .put(`${url}/v1/gislayers/${id}`, data, header)
    .then((res) => {
      if (res.status === 200) {
        toast.success("Map layer updated successfully.");
      }
      if (res.status === 403) {
        // refreshToken()
        localStorage.clear();
      }
    })
    .catch((err) => {
      toast.error("Unable to remove map layer !!");
    });
};

const getMapLayerById = async (id) => {
  const header = await token();
  const response = axios
    .get(`${url}/v1/gislayers/${id}`, header)
    .then((res) => {
      if (res.status === 403) {
        // refreshToken()
        localStorage.clear();
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      return null;
    });
  return response;
};

const getMapLayerList = async () => {
  const header = await token();
  const response = await axios
    .post(`${url}/v1/gislayers/search`, {}, header)
    .then((res) => {
      if (res.status === 403) {
        // refreshToken()
        localStorage.clear();
      } else {
        return res.data.data;
      }
    })
    .catch((err) => {
      return null;
    });
  return response;
};

export {
  addNewMapLayer,
  removeMapLayer,
  updateMapLayer,
  getMapLayerById,
  getMapLayerList,
};
