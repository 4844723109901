import React, { useState, useRef } from "react";
import { Button, IconButton, Typography, useTheme } from "@mui/material";
import useStyles from "./style";
// import { Button } from "@material-ui/core";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import DropZone from "../dropZone";
import { claimUploadFile } from "../../../services/apiCalls/claimApi";
import DetailTable from "../../organisms/detailTable/detailTable";
import { uploadFileData, UploadFileColumns } from "../../../dummyTableData";
import ProgressBar from "../progressBar/progressBar";

const UploadContainer = (props) => {
  const { upload } = props;
  const theme = useTheme();
  const [selectedFile, setSelectedFile] = useState(null);
  const [claimedData, setClaimedData] = useState([]);
  const [loader, setLoader] = useState(false);
  const classes = useStyles();

  const handleFileChange = (file) => {
    file && file[0] && setSelectedFile(file[0]);
  };

  const handleUploadFile = async(file) => {
    if (file !== []) {
      setLoader(true)
      const data = await claimUploadFile(file)
      const uploadedData = uploadFileData(data)
      setClaimedData(uploadedData)
    }
  }; 

  return (
    <>
      <div className={classes.upload_box}>
        <Typography
          color={theme.mixins.textColor}
          className={classes.upload_heading}
          variant="h3"
        >
          Upload File here
        </Typography>
        <DropZone handleSetFile={handleFileChange}/>
        <Button
          className={classes.upload_button}
          onClick={() => handleUploadFile(selectedFile)}
          disabled={selectedFile === null || selectedFile.length === 0}
        >
          Upload
        </Button>
        {claimedData.length > 0 ?
          <DetailTable
            data={claimedData}
            columns={UploadFileColumns}
            page="uploadFile"
          />
          : loader ? <ProgressBar/> : null
        }
      </div>
    </>
  );
};

export default UploadContainer;
