import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import LoginForm from "../../components/organisms/loginForm/loginForm";
import UseStyles from "./style";
import { Navigate } from "react-router-dom";

function LogIn() {
  const classes = UseStyles();
  const { Auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  if (Auth.isLoggedIn) {
    if (Auth.user.isActive) {
      return <Navigate to="/dashboard/home" />;
    } else {
      return <Navigate to="/" />;
    }
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />
      <Container maxWidth="xs" className={classes.login}>
        <div className={classes.paper}>
          <LoginForm classes={classes} />
        </div>
      </Container>
    </Container>
  );
}

export default LogIn;
