import { createColumnHelper } from "@tanstack/react-table";
import PdfActions from "./components/molecules/pdfActions/pdfActions";
import NoPdfIcon from "./assets/icons/no_pdf_icon.svg";

const TableData = [
  {
    claimId: 1,
    policyNumber: 1,
    lossDate: "2022-10-12 06:20:03",
    claimDate: "1979-11-11 12:13:25",
    claimType: "enim",
    address: "Accusamus quaerat modi aut soluta architecto facilis porro.",
    status: "sit",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -36.848461,
      longitude: 174.763336,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 2,
    policyNumber: 2,
    lossDate: "2022-10-02 19:46:33",
    claimDate: "2022-07-22 13:24:45",
    claimType: "distinctio",
    address: "Impedit nam aut aut magni est rerum.",
    status: "modi",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -43.52565,
      longitude: 172.639847,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 3,
    policyNumber: 3,
    lossDate: "2022-09-15 02:15:37",
    claimDate: "1989-03-20 21:47:54",
    claimType: "laudantium",
    address: "Expedita quia vero explicabo tenetur officiis et minima omnis.",
    status: "repellat",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -37.683334,
      longitude: 176.166672,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 4,
    policyNumber: 4,
    lossDate: "1973-01-12 03:14:34",
    claimDate: "2007-02-20 10:21:58",
    claimType: "mollitia",
    address: "Natus quos quibusdam exercitationem ut animi eum.",
    status: "ullam",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -38.685692,
      longitude: 176.070206,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 5,
    policyNumber: 5,
    lossDate: "1996-07-27 06:02:00",
    claimDate: "2010-04-27 19:56:16",
    claimType: "aut",
    address:
      "Blanditiis odit aut iure repudiandae nemo sapiente voluptatibus nisi.",
    status: "laudantium",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -41.270634,
      longitude: 173.283966,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 6,
    policyNumber: 6,
    lossDate: "1983-12-20 07:11:57",
    claimDate: "2013-06-14 19:13:37",
    claimType: "tenetur",
    address: "Iure rerum labore quae quis nam.",
    status: "et",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -41.209164,
      longitude: 174.908051,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 7,
    policyNumber: 7,
    lossDate: "2005-03-26 14:18:03",
    claimDate: "1986-11-20 08:45:04",
    claimType: "unde",
    address: "Dicta id eum placeat quia fugiat.",
    status: "voluptas",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -41.28664,
      longitude: 174.77557,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 8,
    policyNumber: 8,
    lossDate: "2002-07-26 19:29:07",
    claimDate: "1993-07-31 04:30:53",
    claimType: "consequatur",
    address: "Eos maiores error aliquid est.",
    status: "incidunt",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -36.99282,
      longitude: 174.87986,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 9,
    policyNumber: 9,
    lossDate: "2018-03-16 00:10:01",
    claimDate: "1971-10-22 02:06:34",
    claimType: "rerum",
    address: "Asperiores quaerat architecto et neque consectetur qui.",
    status: "ducimus",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -36.8,
      longitude: 174.75,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 10,
    policyNumber: 10,
    lossDate: "1972-06-28 02:06:13",
    claimDate: "2001-08-02 11:51:24",
    claimType: "quasi",
    address: "Recusandae autem illum quia molestiae officiis deleniti.",
    status: "natus",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -45.87416,
      longitude: 170.50361,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport: "",
    abcReport: "",
  },
  {
    claimId: 11,
    policyNumber: 11,
    lossDate: "2010-12-31 10:54:54",
    claimDate: "1996-04-16 05:05:07",
    claimType: "optio",
    address: "Pariatur asperiores facilis et corporis perspiciatis aliquid.",
    status: "sed",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -45.87416,
      longitude: 170.50361,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 12,
    policyNumber: 12,
    lossDate: "1976-12-01 19:15:34",
    claimDate: "1971-12-27 23:57:11",
    claimType: "reiciendis",
    address: "Voluptas quia a animi nulla dolorum.",
    status: "dolor",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -40.35636,
      longitude: 175.61113,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 13,
    policyNumber: 13,
    lossDate: "1991-03-17 12:07:42",
    claimDate: "1990-02-18 20:23:35",
    claimType: "et",
    address: "Illo quis molestiae quis quo soluta.",
    status: "repudiandae",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -38.13874,
      longitude: 176.24516,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 14,
    policyNumber: 14,
    lossDate: "1990-07-14 20:29:28",
    claimDate: "1987-10-24 17:25:25",
    claimType: "aut",
    address: "Voluptatibus necessitatibus nihil minus id dolor.",
    status: "dolorem",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -39.06667,
      longitude: 174.08333,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 15,
    policyNumber: 15,
    lossDate: "2017-12-03 23:03:44",
    claimDate: "1995-03-13 01:48:59",
    claimType: "vel",
    address: "Illo aut libero cupiditate a aut voluptatem.",
    status: "quo",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -46.4,
      longitude: 168.35,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 16,
    policyNumber: 16,
    lossDate: "1985-07-01 13:25:23",
    claimDate: "2004-12-19 19:41:38",
    claimType: "nihil",
    address: "Necessitatibus corrupti quis dolorem et et sed aperiam.",
    status: "quisquam",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -39.6381,
      longitude: 176.84918,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 17,
    policyNumber: 17,
    lossDate: "1985-05-11 01:36:50",
    claimDate: "2021-06-11 01:09:52",
    claimType: "animi",
    address: "Dignissimos natus ut enim et non aut ipsa.",
    status: "placeat",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -39.4926,
      longitude: 176.91233,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 18,
    policyNumber: 18,
    lossDate: "2008-02-09 15:00:24",
    claimDate: "2004-05-06 16:09:47",
    claimType: "quia",
    address: "Sint facere fugiat minus blanditiis.",
    status: "possimus",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -36.96807,
      longitude: 174.79875,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 19,
    policyNumber: 19,
    lossDate: "1983-10-31 07:53:07",
    claimDate: "2000-09-04 06:07:58",
    claimType: "ut",
    address:
      "Est voluptate incidunt dolores enim laudantium suscipit qui quas.",
    status: "quam",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -41.13827,
      longitude: 175.0502,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
  {
    claimId: 20,
    policyNumber: 20,
    lossDate: "2000-05-19 09:36:28",
    claimDate: "2022-07-31 01:33:08",
    claimType: "alias",
    address: "Suscipit aliquid qui mollitia sequi.",
    status: "et",
    value: 1,
    geoCoordinate: {
      // x: "49.9999",
      // y: "51.2341",
      latitude: -35.73167,
      longitude: 174.32391,
    },
    lossAdjusterType: "Adjuster  Type ABC",
    lossAdjusterFirm: "Loss  Adjuster Form Name",
    lossAdjusterPersonName: "Mr. John Thomas",
    lossAdjusterMobile: "+91-99999XXXXX",
    flag: "urgent",
    uploadedReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    claimReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
    abcReport:
      "https://dap-s3-dev-bucket.s3.us-east-1.amazonaws.com/test.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAynNxoEyQeNzFF6fsnDTB2lLzIsvOMc71brGTNE0IskoCIBL3FzgBbakXUJQlEsygz3%2BmYzy8smAnZj9bQmpaLupoKogECIf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDUyMjM4MDYyMzMyIgzrseuKZz5fVIU3QUEq3AMAoP0lewNN5WDmkMiwqYS4vILqu%2FMHyCti4a%2FmrOYfppoDLN7ZpAodzQAuBNZ%2F77KeOPJbB1xRW20%2FpHIko%2B%2BCISAmFX22KeQ3dheCpk0GN4q8nr3F2CmOU%2F6Utj2qNeHHkO5VUiFVqZ0UtuHBIDxxvbBP5VXN4cmbiMXp5H%2BoCI%2BPQxXMpInFIFIVyvaSXaPy2WScRLGi3S5hEOlUcFGl5G3nE9djaXkcSRlXkrGLNxZXzXKiDWgvgEQWwQqhTXWR%2FANfYlKUxQr9H5GGnAsWAEMJe1oDgOkeAkDkxUiVMghddd9%2FgxWjAmJDa0bis8MrTmqlspmcpWzIyL3T%2BPu7a89jZ4f%2Fgbl%2BB5L5Qf%2FgLCwWjLt7LYlvg4U5x5JQ03PPCrT8FC%2FpLI%2BG%2B%2BNih7%2BnkbwOgtN2STHJkhKfdNvE0G6EU30fFayibwzNYQ6ENXF5%2FmHyxgofZD97JaisdiVJW4e8Wc2r43nAV0wNeWkJN%2B4fBI0WQRuxaBUn%2FWKfyqX18bu2ROP1xMYEFWkQe50wrnjgcT6%2Fm5Ef9mUyzdP2ywVPHIjZMHyh9s10ZpBtidL%2FD3SlzVPo2q%2FpcsoZ2NbMQSGFPKDYgicIl0l7ySvtibjjqZi8cgrIgC6I3jCvj86aBjqUAl1wvHduPvD3Ug56JZg4Ud7DB1j110pVITMOmdmz7fi0HTBC5o3JrPt8uxdYNYJ1%2B1j5iwuebV6hXHrdVvg8MRAPS0XzRhHMOwRdMVR5GfXeIInnJnxkOU%2B7socRyc8LjNGjATSyiHPgOvQ4rRf8BOqAmXrPOm53PT3VuKpgtPlAOdth5L9JAg4ZdAOneXbPGBP%2BUBPGcxpsKuFAtN2GmzKohSYzKyjuQTskBQ28QAN9BufTR0lYTG7DavG8ddoZoSVr7k7XYYXJ%2Fz%2FWBUiQvVKDbXdWTo8QQq%2FlQSD2Fg00qZ1QiZMhSDMqISCi3OTq5ck32HSl%2B5n%2FdOEZLjfJ6spJDTJrd0uPUb6sheyK3MeWnyQyNw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221022T063128Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIAQYKNBSL6AHOE6QEQ%2F20221022%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=2da83ce9cea44118e2e7dc82a9b724a8dc12409b4f10c926c6d72c94478a1b3d",
  },
];

const uploadFileData = (Data) => {
  let data = [];
  Data?.claimInfos.map((i) => {
    data.push({ ...i, error: "" });
  });
  Data?.rowErrors?.map((i) => {
    let r = i?.content?.split(",");
    data.push({
      claimNumber: r[0],
      policyNumber: r[1],
      location: r[2] && r[3] !== "" ? `POINT (${r[2]} ${r[3]})` : "",
      address: r[4],
      lossDate: r[5],
      claimRaisedDate: r[6],
      claimType: r[7],
      lossAdjuster: r[8],
      phoneNumber: r[9],
      urgent: r[10],
      claimStatus: r[11],
      error: i?.errors?.length + " " + "error",
    });
  });
  return data;
};

const columnHelper = createColumnHelper();

const detailColumns = [
  columnHelper.accessor("claimNumber", {
    cell: (info) => info.getValue(),
    header: () => <span>Claim ID</span>,
    sortingFn: "alphanumeric",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("policyNumber", {
    cell: (info) => info.getValue(),
    header: () => <span>Policy Number</span>,
    sortingFn: "alphanumeric",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("lossDate", {
    cell: (info) => info.getValue(),
    header: () => <span>Loss date</span>,
    enableSorting: false,
    filterFn: "inNumberRange",
  }),
  columnHelper.accessor("claimRaisedDate", {
    cell: (info) => info.getValue(),
    header: () => <span>Claim date</span>,
    enableSorting: false,
    filterFn: "inNumberRange",
  }),
  columnHelper.accessor("claimType", {
    cell: (info) => info.getValue(),
    header: () => <span>Claim type</span>,
    enableSorting: false,
  }),
  columnHelper.accessor("address", {
    cell: (info) => info.getValue(),
    header: () => <span>Address</span>,
    enableColumnFilter: false,
  }),
  columnHelper.accessor("claimStatus", {
    cell: (info) => info.getValue(),
    header: () => <span>Status</span>,
    sortingFn: "basic",
    enableColumnFilter: false,
  }),
  columnHelper.display({
    id: "reports",
    cell: (props) =>
      props.row.original.claimReports?.length > 0 ? (
        <PdfActions reports={props.row.original.claimReports} />
      ) : (
        <img src={NoPdfIcon} alt="pdfIcon" />
      ),
    header: () => <span>Reports</span>,
  }),
];

const UploadFileColumns = [
  columnHelper.accessor("claimNumber", {
    cell: (info) => info.getValue(),
    header: () => <span>Claim ID</span>,
    sortingFn: "alphanumeric",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("policyNumber", {
    cell: (info) => info.getValue(),
    header: () => <span>Policy Number</span>,
    sortingFn: "alphanumeric",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("lossDate", {
    cell: (info) => info.getValue(),
    header: () => <span>Loss date</span>,
    enableSorting: false,
  }),
  columnHelper.accessor("claimRaisedDate", {
    cell: (info) => info.getValue(),
    header: () => <span>Claim date</span>,
    enableSorting: false,
  }),
  columnHelper.accessor("claimType", {
    cell: (info) => info.getValue(),
    header: () => <span>Claim type</span>,
    enableSorting: false,
  }),
  columnHelper.accessor("address", {
    cell: (info) => info.getValue(),
    header: () => <span>Address</span>,
    enableColumnFilter: false,
  }),
  columnHelper.accessor("claimStatus", {
    cell: (info) => info.getValue(),
    header: () => <span>Status</span>,
    sortingFn: "basic",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("error", {
    cell: (info) => info.getValue(),
    header: () => <span>Error</span>,
    enableColumnFilter: false,
  }),
];

export { TableData, detailColumns, UploadFileColumns, uploadFileData };
