import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerBar: {
    background: "#2F2F5D",
    zIndex: "9999",
    padding: "5px 20px",
    display: "flex",
    alignItems: "center",
    position: 'sticky',
    top: '0',
    height: '69px',

  },
  logoContainer: {
    width: "365px",
  },
  rightMenu: {
    marginLeft: "auto",

    "& span": {
      color: "#fff",

      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  mapFilterBox: {
    display: "flex",
    width: "100%",
    height: "45px",
    alignItems: "flex-end",
  },
  searchBar: {
    position: 'relative',
    maxWidth: '315px',
    width: '100%',
    "& label": {
      display: 'none',
    },
    "& div": {
      width: '100%',
      height: "40px",
      background: '#fff',
      borderRadius: '8px',
      overflow: 'hidden',
    },
    "& input": {
      width: '100%',
      maxWidth: "260px",
      background: '#fff',
    },
    "& button": {
      position: 'absolute',
      right: '0',
      top: '-6px',
    },
    "& fieldset": {
      border: 'none',
      outline: 'none',
    }
  },
  claimStatusDate: {
    marginLeft: '15px',
    width: '220px',
    position:'relative',
    "& .datePickerBox":{
      left:'0px',
  },
    "& span": {
      marginBottom:'2px',
    },
    "& .MuiInput-formControl": {
      background: '#fff',
      padding: ' 5px 20px',
      height: "40px",
      borderRadius: '20px',
      marginTop: '0',

    },
    "& label": {
      display: 'none',
    },
    "& .MuiInput-underline:after":{
      display: "none"
    },
    "& .MuiInput-underline:before":{
      display: "none"
    }
  },
  claimStatus: {
    maxWidth: '220px',
    width: '100%',
    marginTop: '0',
    marginLeft: '15px',
    "& span": {
      marginLeft: "20px",
      marginBottom:'2px',
    },
    "& div": {
      width: '100%',
      maxWidth:'220px',
    },
    "& svg": {
      zIndex: '9',
      color:'#fff',
    },
    "& select": {
      border: '1px solid #fff',
      padding: ' 0px 15px',
      borderRadius: '20px',
      color:'#fff',
      height:'39px',
      "& option": {
        color: "#34346F",
      },
    },
    "& .MuiSelect-select:focus": {
      borderRadius: '20px',
     },
    "& fieldset": {
      border: 'none',
      outline:'none',
      color:'#fff',    
    },  
  },
  clearBtn:{
    width: "100%",
    padding: "6px 15px 6px 10px",
    borderRadius: "20px",
    border: "1px solid #fff",
    height: "40px",
    marginLeft: "0px",
    fontSize: "16px",
    fontWeight:'normal',
    textTransform:'capitalize',
    color:'#bcbced',
    "& span": {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      "& .MuiTouchRipple-root":{
        display: "none !important",
      }
    }
  },
  clearBtnArrow:{
      transform: 'rotate(90deg)',
  },
  clearLastBtn:{
    width:'100px',
    marginLeft:'15px',
    '& span':{
     justifyContent: 'center',
     color:'#bcbced',
     fontSize:'15px',
  },
  },
  labelField: {
    marginLeft: "20px",
    color:'#bcbced',
      fontSize:'12px',
  },
  dateRangeText:{
    fontSize: '11px',
    paddingTop: '4px'
  }
}));

export default useStyles;
