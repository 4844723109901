import { toast } from "react-toastify";
import axios from "axios";
import { checkRefereshToken } from "../../utils/utils";

const url = `${process.env.REACT_APP_USER_API_URL}`;

const token = async () => {
  await checkRefereshToken();
  const tokenData = JSON.parse(window.localStorage.getItem("token"));

  return {
    headers: {
      Authorization: `Bearer ${tokenData.token}`,
    },
  };
};

const userDetails = async () => {
  const header = await token();
  const response = await axios
    .get(`${url}/v1/personal/profile`, header)
    .then((res) => {
      if (res.status === 403) {
        // refreshToken()
        localStorage.clear();
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      toast.error(err.response.message);
    });
  return response;
};

const refreshToken = () => {
  const tokenData = JSON.parse(window.localStorage.getItem("token"));
  axios
    .post(`${url}/tokens/refresh`, tokenData)
    .then((res) => {
      const response = {
        token: res.data.token,
        refreshToken: res.data.refreshToken,
      };
      window.localStorage.setItem("token", JSON.stringify({ response }));
    })
    .catch((err) => {
      console.log(err);
    });
};

const addNewUser = async (data) => {
  const header = await token();
  axios
    .post(`${url}/v1/users`, data, header)
    .then((res) => {
      if (res.status === 200) {
        toast.success("New user added successfully!");
      }
      if (res.status === 403) {
        // refreshToken()
        localStorage.clear();
      }
    })
    .catch((err) => {
      toast.error("Unable to add new user !!");
    });
};

const removeUser = async (id) => {
  const header = await token();
  axios
    .delete(`${url}/v1/users/${id}`, header)
    .then((res) => {
      if (res.status === 200) {
        toast.success("User removed successfully.");
      }
      if (res.status === 403) {
        // refreshToken()
        localStorage.clear();
      }
    })
    .catch((err) => {
      toast.error("Unable to remove user !!");
    });
};

const updateUser = async (id, data) => {
  const header = await token();
  axios
    .put(`${url}/v1/users/${id}`, data, header)
    .then((res) => {
      if (res.status === 200) {
        toast.success("User updated successfully.");
      }
      if (res.status === 403) {
        // refreshToken()
        localStorage.clear();
      }
    })
    .catch((err) => {
      toast.error("Unable to update user !!");
    });
};

const getUserById = async (id) => {
  const header = await token();
  const response = axios
    .get(`${url}/v1/users/${id}`, header)
    .then((res) => {
      if (res.status === 403) {
        // refreshToken()
        localStorage.clear();
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      return null;
    });
  return response;
};

const getUserList = async (data) => {
  const header = await token();
  const response = await axios
    .post(`${url}/v1/users/search`, data, header)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      if (res.status === 403) {
        refreshToken();
      }
    })
    .catch((err) => {
      return null;
    });
  return response;
};

export {
  addNewUser,
  removeUser,
  updateUser,
  getUserById,
  getUserList,
  userDetails,
};
