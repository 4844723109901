import React, { useEffect, useState } from 'react'
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Collapse, ListItemButton, Popover, Typography } from "@mui/material";
import { Box, Button } from "@material-ui/core";
import CheckBox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CategoryList, LayerListByCategory } from "../../templates/map-viewer/MapComponent/layerData";
import { useDispatch, useSelector } from 'react-redux';
import { getMapLayerList, removeMapLayer } from '../../../services/apiCalls/mapApi';
import UpdateMapLayer from '../../templates/map-viewer/MapComponent/UpdateMapLayer';
import { newMapLayerForm } from '../../../helpers/formData';

const MapDrawer = ({ classes, setPopOver, expand, setExpand }) => {
    const { Navigation, LayerReducer } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [actionPopover, setActionPopover] = useState([]);
    const isAdmin = Boolean(JSON.parse(window.localStorage.getItem("state"))?.Auth?.user?.role === "admin");
    const [category, setCategory] = useState();
    const [categoryLayer, setCategoryLayer] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [layerToUpdate, setLayerToUpdate] = useState(newMapLayerForm);

    useEffect(() => {
        if(LayerReducer?.length > 0 ){
            const categoriesList = CategoryList(LayerReducer);
            const layerByCategory = LayerListByCategory(LayerReducer);
            setCategory(categoriesList);
            setCategoryLayer(layerByCategory);
        
        
        }
    },[LayerReducer])

    const handleDelete = (item) => {
        const itemToDelete = LayerReducer.filter(layer => layer.name === item)
        removeMapLayer(itemToDelete[0].id)
        dispatch({
            type: "REMOVE_LAYER",
            payload: itemToDelete
        })
    }

    const handleActionPopover = (action) => {
        if (actionPopover.includes(action)) {
            let data = actionPopover.filter(item => item !== action)
            setActionPopover(data)
        }
        else {
            setActionPopover([...expand, action])
        }
    }

    const handleCategory = (category) => {
        if (expand.includes(category)) {
            let data = expand.filter(item => item !== category)
            setExpand(data)
        }
        else {
            setExpand([...expand, category])
        }
        setPopOver(false)
    }

    const handleMapChange = (val) => {
        const data = JSON.parse(window.localStorage.getItem('layer'));
        if(data.includes(val)){
            const filteredData = data.filter(item => item !== val)
            window.localStorage.setItem('layer',JSON.stringify(filteredData));
        }
        else{
            window.localStorage.setItem('layer',JSON.stringify([...data,val]));
        }
        let visibility = window.mapLayer.map.findLayerById(val).visible
        window.mapLayer.map.findLayerById(val).visible = !visibility
    }

    const handleModal = (item) => {
        const itemToUpdate = LayerReducer.filter(layer => layer.name === item);
        setLayerToUpdate({...layerToUpdate,...itemToUpdate[0]});
        setOpenModal(true);
    }

    const closeModal = () => {
        setOpenModal(false)
    }

    // const convertToTitleCase = (str) => {
    //     return str
    //         .toLowerCase()
    //         .split(" ")
    //         .map(function (word) {
    //             return word.charAt(0).toUpperCase() + word.slice(1);
    //         })
    //         .join(" ").slice(0,25);
    // };

    return (
        <>
            {
                category?.map((item, index) => (
                    <Box key={index} className={classes.mapsideBarListItem}>
                        <ListItemButton onClick={() => handleCategory(item)} className={classes.collapseDrwMap}>
                            <ListItemText
                                primary={item}
                                classes={{ primary: classes.navText }}
                                className={`${classes.nav} ${!Navigation.isOpen && classes.hideNav
                                    }`}
                            />
                            {Navigation.isOpen === true ? expand.includes(item) ? <RemoveIcon className={classes.icon} /> : <AddIcon className={classes.icon} /> : null}
                        </ListItemButton>
                        <Collapse in={expand.includes(item)} timeout="auto" unmountOnExit className={`${classes.collapseDrwInputList} ${!Navigation.isOpen && classes.hideNav}`}>
                            <Box className={classes.mapSubmenuListItem}>
                                <List component="div" disablePadding>
                                    {categoryLayer?.[item]?.map((layerItem, index) => (
                                        <ListItemButton key={index} className={classes.layerBox} sx={{ pl: 4 }}>
                                            <Box className= {classes.labelBox} onClick={() => handleMapChange(layerItem)}>
                                                <ListItemIcon className={classes.collapseDrwInput}>
                                                    <CheckBox
                                                        className={classes.collapseInputInner}
                                                        checked={window?.mapLayer?.map?.findLayerById(layerItem)?.visible || false}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={layerItem}
                                                    classes={{ primary: classes.navText }}
                                                    className={`${classes.nav} ${!Navigation.isOpen && classes.hideNav
                                                        }`}
                                                />
                                            </Box>
                                            {isAdmin ? 
                                                <Box>
                                                    <Button onClick={() => handleActionPopover(layerItem)} >
                                                        <MoreVertIcon className={classes.icon} />
                                                    </Button>
                                                    {actionPopover.includes(layerItem) ?
                                                        <div id="toolbarDiv" className="sidebarselectpopover">
                                                            <Typography className={classes.actionPopUp} sx={{ p: 1 }}>
                                                                <VisibilityOutlinedIcon className={classes.actionIcon} />
                                                                <DeleteOutlinedIcon className={classes.actionIcon} onClick={(e) => handleDelete(layerItem)}/>
                                                                <EditOutlinedIcon className={classes.actionIcon} onClick={() => handleModal(layerItem)}/>
                                                            </Typography>
                                                        </div>
                                                        : null}
                                                </Box> : null
                                            }
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Box>
                        </Collapse>
                    </Box>
                ))
            }
            <UpdateMapLayer openModal={openModal} closeModal={closeModal} layerToUpdate={layerToUpdate} setLayerToUpdate={setLayerToUpdate}/>
        </>
    )
}

export default MapDrawer