import React, { useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useMatch,
} from "react-router-dom";
import LogIn from "../pages/login/login";
import AccessRequest from "../pages/404/404";
import Dashboard from "../pages/dashboard/dashboard";
import { useSelector } from "react-redux";

const CustomRoutes = () => {
  const { pathname } = useLocation();
  const { Auth } = useSelector((state) => state);
  return (
    <Routes>
      <Route path="/" element={<LogIn />} />
      <Route
        exact
        path="/dashboard/:screen"
        element={
          Auth.isLoggedIn ? <Dashboard /> : <Navigate to={"/"} replace />
        }
      />
      <Route
        exact
        path={"/404"}
        element={<AccessRequest />}
        // identifier="access"
      />
      <Route
        path={"/404/:screen"}
        element={<AccessRequest />}
        // identifier="access"
      /> 
    </Routes>
  );
};

export default CustomRoutes;
