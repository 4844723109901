import UseStyles from "./style";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { red } from "@material-ui/core/colors";
import { Button, Grid, Typography, IconButton } from "@material-ui/core/";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ModalComponent = ({ closeModal, handleSubmit, show, data, label }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = UseStyles();

  const onDocumentLoadSuccess = (pdfObject) => {
    setNumPages(pdfObject._pdfInfo.numPages);
  };

  if (!show) return null;
  return ReactDOM.createPortal(
    <>
      <div
        className={`${classes.modal_wrapper} ${show ? classes.modal_show : ""}`}
      >
        <div className={classes.modal_content}>
          <div className={classes.modal_header}>
            <Typography
              varient="h2"
              align="center"
              className={classes.modal_title}
            >
              {label}
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={closeModal}
              className={classes.closeModal}
            >
              <CloseOutlinedIcon sx={{ color: "#000" }} />
            </IconButton>
          </div>
          {data ? (
            <div className={classes.modal_body}>
              <div>
                <Document
                  file={{
                    data: data,
                  }}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={(error) => console.log("Inside Error", error)}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
                <Typography className={classes.pageContainer}>
                  Page {pageNumber} of {numPages}
                </Typography>
              </div>
            </div>
          ) : (
            <>Loading...</>
          )}
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default ModalComponent;
