import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal_wrapper: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    opacity: 0,
    transition: "all 0.3s ease-in-out",
    pointerEvents: "none",
    zIndex: 10,
  },
  modal_content: {
    position: "relative",
    backgroundColor: `${theme.mixins.white}`,
    padding: 16,
    borderRadius: 16,
    marginTop: 80,
    height: "85%",
    minWidth: '500px'
  },
  modal_header: {
    //       //   -webkit-box-shadow: 0 8px 6px -6px black;
    //       //   -moz-box-shadow: 0 8px 6px -6px black;
    boxShadow: "0 8px 6px -6px black",
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingBottom: 40,
    "& .MuiTypography-body1": {
      fontWeight: "bold",
      fontSize: "24px",
    },
  },
  closeModal: {
    position: "absolute",
    right: 0,
  },
  modal_body: {
    backgroundColor: `${theme.mixins.white}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "90%",
    overflowY: "auto",
  },
  modal_show: {
    opacity: 1,
    pointerEvents: "visible",
  },

  pageContainer: {
    position: "sticky",
    bottom: "14px",
    display: "flex",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "400",
  },
}));
export default useStyles;
