import React from "react";
import useStyles from "./style";
import {
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@material-ui/core";

const InputField = (props) => {
  const  {handleChange, label, type, value, children, name, placeholder } = props;
  const classes = useStyles();

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>{props.label}</InputLabel>
        <OutlinedInput 
          type= {(values.showPassword && type === 'password') ? 'text': type}
          value={value}
          onChange={(e) => handleChange(e)}
          label={label}
          name = {name}
          placeholder = {placeholder}
          className={classes.inputField}
          endAdornment={
            props.children ? (<InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {children}
            </IconButton>
          </InputAdornment>) : ''
          }
        />
        {/* {props.type === "text" ? (
          <OutlinedInput
            type= {type}
            value={value}
            onChange={(e) => props.handleUserIdChange(e.target.value)}
            label={label}
          />
        ) : (
          <OutlinedInput
            type={values.showPassword ? "text" : "password"}
            value={props.value}
            onChange={(e) => props.handlePasswordChange(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label={props.label}
          />
        )} */}
      </FormControl>
    </div>
  );
};
export default InputField;
