const Users = {
  ADMIN: {
    isLoggedIn: true,
    user: {
      isActive: true,
      name: "Demo",
      role: "admin",
    },
  },
  AGENCY: {
    isLoggedIn: true,
    user: {
      isActive: true,
      name: "Test",
      role: "agency",
    },
  },
  TONKIN: {
    isLoggedIn: true,
    user: {
      isActive: true,
      name: "John",
      role: "tonkin",
    },
  },
};

export default Users;
