import { makeStyles } from "@material-ui/core/styles";
import { padding } from "@mui/system";
const useStyles = makeStyles((theme) => ({
  pdfContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  pdfIcon: {
    width: "20px",
    "&:hover": {
      cursor: "pointer",
    },
  },

  reportContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0 10px",
    alignItems: "center",
    gap: "5px",
  },

  reportName: {
    color: "#7C7C7C",
    fontSize: "12px",
  },

  actions: {
    color: "#7C7C7C",
    display: "flex",
    justifyContent: "space-between",
    width: "40px",
  },

  icon: {
    fontSize: "14px",

    "&:hover": {
      cursor: "pointer",
    },
  },
  downloadBtn: {
    margin: "0px",
    padding: "0px",
    width: "20px"
  }
}));
export default useStyles;
