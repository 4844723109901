const newMapLayerForm = {
    "category": "",
    "name": "",
    "url": "",
    "layerType": "",
    "authenticationType": "None",
    "apiKey": "gdgbg",
    "userName": "vcbcvb",
    "password": "vcbvcb",
    "authUrl": "vcbcv"
}

const newUserForm = {
    "userType": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "agencyId": ""
}

export {
    newMapLayerForm,
    newUserForm
}