import { makeStyles } from "@material-ui/core/styles";
import BackgroundImage from "../../assets/login.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: `${theme.mixins.purple}`,
    background: `url(${BackgroundImage})`,
    backgroundSize: "100% 100%",
    position: "relative",
  },
  paper: {
    marginTop: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logoContainer: {
    position: "absolute",
    margin: "44px 0px 0px 34px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  login: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: '50px'
  },
  logo_container: {},
  errorStyle: {
    fontSize: "13px",
  },
  h2: {
    fontSize: "20px",
    margin: "30px 0 10px",
    fontWeight: 700,
    color: "#525252",
  },
}));

export default useStyles;
