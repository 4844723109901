import {
	CognitoUserPool,
	CognitoUserAttribute,
	CognitoUser,
} from 'amazon-cognito-identity-js';

var poolData = {
	UserPoolId: `${process.env.REACT_APP_USERPOOL_ID}`, // Your user pool id here
	ClientId: `${process.env.REACT_APP_CLIENT_ID}`, // Your client id here
};

export default new CognitoUserPool(poolData);