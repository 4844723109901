import FileSaver from "file-saver";
import { exportClaim, openPDFFile } from "../services/apiCalls/claimApi";
import { toast } from "react-toastify";

const urltoFile = (url, filename, mimeType) => {
  return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { FileSaver.saveAs(new File([buf], filename, { type: mimeType })) })
  );
}

const base64toUint8Array = (data) => {
  const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
      out[length] = bytes.charCodeAt(length);
  }
  return out;
};

const exportClaimExcel = async(filter) => {
  toast.info("Downloading.....")
  let data = await exportClaim(filter);
  const base64String = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
  urltoFile(base64String,'ClaimExports.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
}

const downloadPdf = async(id) => {
  toast.info("Downloading.....")
  let data = await openPDFFile(id);
  const base64String = `data:application/pdf;base64,${data}`;
  urltoFile(base64String,'Reports.pdf','application/pdf');
}

export {
  downloadPdf,
  exportClaimExcel,
  base64toUint8Array
}