import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link, useMatch, useParams } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PlaceIcon from "@material-ui/icons/Place";
import NotificationsIcon from "@material-ui/icons/Notifications";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import MailIcon from "@material-ui/icons/Mail";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MapIcon from '@mui/icons-material/Map';
import StraightenIcon from '@mui/icons-material/Straighten';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Collapse, ListItemButton, MenuItem, Select } from "@mui/material";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Box } from "@material-ui/core";
import CheckBox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import SketchWidget from "../../templates/map-viewer/MapComponent/SketchWidgets";
import MapDrawer from "../../molecules/navMapDrawer/MapDrawer";
import { clearState } from "../../../actions/authAction";


const NavDesktop = (props) => {
  const { classes, userMenu, handleDrawerOpen, handleDrawerClose } = props;
  const { Navigation, Curated } = useSelector((state) => state);
  // const { params } = useMatch();
  const { screen } = useParams();

  const [open, setOpen] = useState(false);
  const [PopOver, setPopOver] = useState(false);
  const [expand, setExpand] = useState([]);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(clearState());
    window.localStorage.removeItem('envelop');
    window.localStorage.removeItem('scale');
    window.localStorage.removeItem('center');
  };

  const handleMenuOpen = () => {
    if (screen === "map viewer") {
      setOpen(!open);
    }
  };

  const handlePopOver = () => {
    setPopOver(!PopOver);
    setExpand([])
  }

  const convertToTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  const handleMapChange = (val) => {
    setPopOver(false);
    const data = JSON.parse(window.localStorage.getItem('layer'));
    if(data.includes(val)){
        const filteredData = data.filter(item => item !== val)
        window.localStorage.setItem('layer',JSON.stringify(filteredData));
    }
    else{
        window.localStorage.setItem('layer',JSON.stringify([...data,val]));
    }
    let visibility = window.mapLayer.map.findLayerById(val).visible
    window.mapLayer.map.findLayerById(val).visible = !visibility
}

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: Navigation.isOpen,
          [classes.drawerClose]: !Navigation.isOpen,
        },
          PopOver ? "selectActive" : ""
        )}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: Navigation.isOpen,
            [classes.drawerClose]: !Navigation.isOpen,
          }),

        }}
      >
        <Box className={classes.dividedSidebar}>
          <List className={classes.leftSpacing}>
            {userMenu.map((text, index) => (
              <Link
                to={
                  text !== "dashboard" ? `/dashboard/${text}` : "/dashboard/home"
                }
                key={index}
                className={classes.link}
              >
                {text !== "map viewer" && text !== "setting" && text !== "account" &&
                  <ListItem
                    button
                    className={classes.navButton}
                    selected={
                      screen === "home" && text === "dashboard"
                        ? true
                        : screen === text
                          ? true
                          : false
                    }
                    onClick={() => setOpen(false)}
                  >
                    <ListItemIcon>
                      {(() => {
                        if (text === "claims") {
                          return <HomeIcon className={classes.icon} />;
                        } else if (text === "status") {
                          return <FolderSpecialIcon className={classes.icon} />;
                        } else if (text === "tracking") {
                          return <VisibilityIcon className={classes.icon} />;
                        } else if (text === "reports") {
                          return <MailIcon className={classes.icon} />;
                        } else if (text === "map viewer") {
                          return <PlaceIcon className={classes.icon} />;
                        } else if (text === "notifications") {
                          return <NotificationsIcon className={classes.icon} />;
                        } else if (text === "add new layer") {
                          return <AddIcon className={classes.icon} />;
                        } else {
                          return <MailIcon className={classes.icon} />;
                        }
                      })()}
                    </ListItemIcon>
                    <ListItemText
                      primary={convertToTitleCase(text)}
                      classes={{ primary: classes.navText }}
                      className={`${classes.nav} ${!Navigation.isOpen && classes.hideNav
                        }`}
                    />
                  </ListItem>}
                {text === 'map viewer' &&
                  <>
                    <Box className={`${classes.collapseDrw} ${open ? "drawerActive" : ""}`} >
                      <ListItemButton onClick={handleMenuOpen} className={classes.collapseDrwMap}>
                        <ListItemIcon className={classes.locationDrw}>
                          <PlaceIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary={convertToTitleCase(text)}
                          classes={{ primary: classes.navText }}
                          className={`${classes.nav} ${!Navigation.isOpen && classes.hideNav
                            }`}
                        />
                        {Navigation.isOpen === true ? open ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} /> : null}
                      </ListItemButton>
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit className={`${classes.collapseDrwInputListNew} ${!Navigation.isOpen && classes.hideNav
                      }`}>
                      <List component="div" disablePadding>
                        <Box className="sidenavpopup">
                          <ListItemButton className="navselect" sx={{ pl: 4 }} onClick={handlePopOver}>
                            <ListItemIcon className={classes.locationDrw}>
                              <StraightenIcon className={classes.icon} />
                            </ListItemIcon>
                            <ListItemText
                              primary={'Select Area'}
                              classes={{ primary: classes.navText }}
                              className={`${classes.nav} ${!Navigation.isOpen && classes.hideNav
                                }`}
                            />
                            {/* <Box>
                              {PopOver
                                ? <SketchWidget view={window.mapLayer} />
                                : null}
                            </Box> */}
                          </ListItemButton>
                          <Box>
                              {PopOver
                                ? <SketchWidget view={window.mapLayer} />
                                : null}
                            </Box>
                          <ListItemButton className="navselect" sx={{ pl: 4 }} onClick={() => setPopOver(false)}>
                            <ListItemIcon className={classes.locationDrw}>
                              <MapIcon className={classes.icon} />
                            </ListItemIcon>
                            <ListItemText
                              primary={'Basemap'}
                              classes={{ primary: classes.navText }}
                              className={`${classes.nav} ${!Navigation.isOpen && classes.hideNav
                                }`}
                            />
                          </ListItemButton>
                          <ListItemButton className="navselect" sx={{ pl: 2 }} onClick={() => handleMapChange("Pointed Location")}>
                          <ListItemIcon className={classes.collapseDrwInput}>
                            <CheckBox
                                className={classes.collapseInputInner}
                                checked={window?.mapLayer?.map?.findLayerById("Pointed Location")?.visible || false}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            </ListItemIcon>
                            <ListItemText
                                primary={`${convertToTitleCase("Claims")}`}
                                classes={{ primary: classes.navText }}
                                className={`${classes.nav} ${!Navigation.isOpen && classes.hideNav
                                    }`}
                            />
                          </ListItemButton>
                          <MapDrawer classes={classes} setPopOver={setPopOver} expand={expand} setExpand={setExpand} />
                        </Box> 
                      </List>
                    </Collapse>
                  </>}
              </Link>
            ))}
          </List>
          <Box className={classes.bottomSidebar}>
            <Link to='/dashboard/setting' className={classes.link}>
              <ListItem button className={classes.navButton} selected={screen === "setting" ? true : false}  >
                <ListItemIcon className={classes.locationDrw}>
                  <SettingsApplicationsIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={convertToTitleCase("Setting")}
                  classes={{ primary: classes.navText }}
                  className={`${classes.nav} ${!Navigation.isOpen && classes.hideNav
                    }`}
                />
              </ListItem>
            </Link>
            <Link to='/dashboard/account' className={classes.link}>
              <ListItem button className={classes.navButton} selected={screen === "account" ? true : false} >
                <ListItemIcon className={classes.locationDrw}>
                  <AccountBoxIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={convertToTitleCase("Account")}
                  classes={{ primary: classes.navText }}
                  className={`${classes.nav} ${!Navigation.isOpen && classes.hideNav
                    }`}
                />
              </ListItem>
            </Link>
            <ListItemButton onClick={handleLogout} className={classes.collapseDrwMap}>
              <ListItemIcon className={`${classes.locationDrw} ${classes.logOutDrw}`}>
                <LogoutIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primary={convertToTitleCase('Logout')}
                classes={{ primary: classes.navText }}
                className={`${classes.nav} ${!Navigation.isOpen && classes.hideNav
                  }`}
              />
            </ListItemButton>
          </Box>
        </Box>
      </Drawer>

      {Navigation.isOpen === false ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          //edge="start"
          className={classes.menuButton}
        >
          <img src="../images/sidebararrowbtnopen.svg" className={classes.arrowButton} />
        </IconButton>
      ) : (
        <IconButton
          color="inherit"
          aria-label="close drawer"
          onClick={handleDrawerClose}
          className={classes.menuButton}
        >
          <img src="../images/sidebararrowbtnclose.svg" className={classes.arrowButton} />
        </IconButton>
      )}
    </>
  );
};
NavDesktop.propTypes = {
  classes: PropTypes.object.isRequired,
  userMenu: PropTypes.array.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};
export default NavDesktop;
