import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { handleEndTime, handleStartTime } from '../../../helpers/dateTimeStamp';

const BasicDateRangePicker = ({filter,setFilter,dateType,tag1,tag2}) => {

    const selectionRange = () => {
        if(dateType === "claimRaisedDate"){     
            const endDate = new Date(filter?.claimEndDate)
            return {
                startDate : filter?.claimStartDate !== undefined ? new Date(filter.claimStartDate) : new Date(),
                endDate: filter?.claimEndDate !== undefined ? new Date(endDate.setDate(endDate.getDate() - 1)) : new Date(),
                key: 'selection'
            };
        }
        if(dateType === "lossDate"){
            const endDate = new Date(filter?.lossEndDate)
            return {
                startDate : filter?.lossStartDate ? new Date(filter?.lossStartDate) : new Date(),
                endDate: filter?.lossEndDate ? new Date(endDate.setDate(endDate.getDate() - 1)) : new Date(),
                key: 'selection'
            };
        }
    }

    const [range, setRange] = useState(selectionRange())

    useEffect(() => {
        if(dateType === "claimRaisedDate"){     
            if(filter?.claimEndDate === undefined && filter?.claimStartDate === undefined){
                setRange({
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection'
                })
            }
        }
        if(dateType === "lossDate"){     
            if(filter?.lossEndDate === undefined && filter?.lossStartDate === undefined){
                setRange({
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection'
                })
            }
        }      
    },[filter])

    const handleSelect = (ranges) => {
        setRange({
            ...range,
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate
        })
        if(dateType === "claimRaisedDate"){
            setFilter({
                ...filter,
                claimStartDate : handleStartTime(ranges.selection.startDate),
                claimEndDate: handleEndTime(ranges.selection.endDate),
            });
        }
        if(dateType === "lossDate"){
            setFilter({
                ...filter,
                lossStartDate : handleStartTime(ranges.selection.startDate),
                lossEndDate: handleEndTime(ranges.selection.endDate)
            });
        }
    }

    document.addEventListener('click', function handleClickOutsideBox(event) {
        const box = document.getElementById(tag1);
        const container = document.getElementById(tag2);
        if (!box?.contains(event.target)) {
          container.style.display = 'none'
        }
        if(box?.contains(event.target)) {
          container.style.display = 'flex'
        }
        if(container?.contains(event.target)) {
          container.style.display = 'flex'
        }
    });


    return (
        <Box id={tag2} className="datePickerBox">
            <DateRangePicker
                ranges={[range]}
                onChange={handleSelect}
            />
        </Box>
    );
}

export default BasicDateRangePicker;
