import { toast } from "react-toastify";
import axios from "axios";
import { checkRefereshToken } from "../../utils/utils";

const url = `${process.env.REACT_APP_CLAIM_API_URL}`;

const token = async () => {
  await checkRefereshToken();
  const tokenData = JSON.parse(window.localStorage.getItem("token"));
  return {
    headers: {
      Authorization: `Bearer ${tokenData.token}`,
    },
  };
};

const getClaimDashboardData = async (data) => {
  const header = await token();
  const response = await axios
    .post(`${url}/v1/claims/dashboard`, data, header)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      if (res.status === 403) {
        // refreshToken();
        localStorage.clear();
      }
    })
    .catch((err) => {
      return null;
    });
  return response;
};

const claimUploadFile = async (file) => {
  const header = await token();
  const formData = new FormData();
  formData.append("file", file);
  const response = await axios
    .post(`${url}/v1/claims/upload`, formData, header)
    .then((res) => {
      if (res.status === 200) {
        toast.success("File uploaded successfully!");
        return res.data;
      }
      if (res.status === 403) {
        // refreshToken();
        localStorage.clear();
      }
    })
    .catch((err) => {
      toast.error("Unable to upload File!!");
      return err.response.data;
    });
  return response;
};

const claimReportUploadFile = async (data) => {
  const header = await token();
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("claimId", data.claimId);
  formData.append("reportName", data.reportName);
  const response = await axios
    .post(`${url}/v1/claims/upload-report`, formData, header)
    .then((res) => {
      if (res.status === 200) {
        toast.success("File uploaded successfully!");
        return res.data;
      }
    })
    .catch((err) => {
      toast.error("Unable to upload File!!");
      return err.response.data;
    });
  return response;
};

const getClaimList = async (filter) => {
  const header = await token();
  const response = await axios
    .post(`${url}/v1/claims/search`, filter, header)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      if (res.status === 403) {
        localStorage.clear();
      }
    })
    .catch((err) => {
      return null;
    });
  return response;
};

const updateClaim = async (id, data) => {
  const header = await token();
  axios
    .put(`${url}/v1/claims/${id}`, data, header)
    .then((res) => {
      if (res.status === 200) {
        toast.success("Claim updated successfully.");
      }
      if (res.status === 403) {
        localStorage.clear();
      }
    })
    .catch((err) => {
      toast.error("Unable to update claim !!");
    });
};

const claimUploadFileStream = async (file) => {
  const header = await token();
  axios
    .post(`${url}/v1/claims/uploadfilestream`, file, header)
    .then((res) => {
      if (res.status === 200) {
        toast.success("File uploaded successfully!");
      }
      if (res.status === 403) {
        // refreshToken();
        localStorage.clear();
      }
    })
    .catch((err) => {
      toast.error("Unable to upload File!!");
    });
};

const exportClaim = async (data) => {
  const authorization = await token();
  const header = {
    headers: {
      ...authorization.headers,
      accept: "application/octet-stream",
      "content-type": "application/json",
    },
  };
  const response = await axios
    .post(`${url}/v1/claims/export`, data, header)
    .then((res) => {
      if (res.status === 200) {
        toast.success("File exported successfully!");
        return res.data;
      }
      if (res.status === 403) {
        // refreshToken();
        localStorage.clear();
      }
    })
    .catch((err) => {
      toast.error("Unable to export File!!");
      return err.response.data;
    });
  return response;
};

const openPDFFile = async (reportId) => {
  const authorization = await token();
  const header = {
    headers: {
      ...authorization.headers,
      accept: "application/octet-stream",
      "content-type": "application/json",
    },
  };
  const response = await axios
    .post(`${url}/v1/claims/download-report`, reportId, header)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      return err.response.data;
    });
  return response;
};

export {
  getClaimDashboardData,
  claimUploadFile,
  claimUploadFileStream,
  getClaimList,
  exportClaim,
  claimReportUploadFile,
  updateClaim,
  openPDFFile,
};
