import React, { lazy } from "react";
import DetailUpload from "./components/templates/detailUpload";

const Home = lazy(() => import("./components/templates/home/home"));
const MapViewer = lazy(() => import("./components/templates/map-viewer/mapViewer"));
const Notifications = lazy(() => import("./components/templates/notifications/notifications") );
const Reports = lazy(() => import("./components/templates/reports/reports"));
// const Tracking = lazy(() => import("./components/templates/tracking/tracking"));
const Users = lazy(() => import("./components/templates/users/users"));
const Status = lazy(() => import("./components/templates/status/status"));
const NewMapLayer = lazy(() => import("./components/templates/newMapLayer/NewMapLayer"));
const Setting = lazy(() => import("./components/templates/setting/Setting"));
const Account = lazy(() => import("./components/templates/account/Account"));

const Config = {
  visibleRecords: 10,
  modelMsgTimer: 5000,
  rejectedItems: [
    "Abusive/inappropriate Content",
    "Competitor Reference",
    "Customer Complaint",
    "Review applies to Different Entity",
    "Personally Identifiable Information",
    "Review is in a Foreign Language",
    "Others",
  ],
  startDate: new Date(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).setUTCHours(0, 0, 0)
  ).toISOString(),
  endDate: new Date(new Date(Date.now()).setUTCHours(23, 59, 59)).toISOString(),
  screens: {
    home: <Home />,
    users: <Users />,
    status: <Status />,
    // tracking: <Tracking />,
    reports: <Reports />,
    "map viewer": <MapViewer />,
    notifications: <Notifications />,
    claims: <DetailUpload />,
    "add new layer": <NewMapLayer />,
    setting: <Setting />,
    account: <Account />
  },
  roles: {
    admin: ["map viewer", "users","add new layer"],
    agency: [
      "claims",
      "status",
      // "tracking",
      "reports",
      "map viewer",
      "notifications",
      "setting",
      "account",
    ],
    tonkin: ["map viewer", "tracking", "reports"],
  },

  initialUserDataFormValue() {
    return {
      page: 1,
      size: Config.visibleRecords,
    };
  },
};

export default Config;
