import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  inputField: {
    width: "100%",
    height: '48px',
    '& .MuiInputBase-input': {
      color: `${theme.mixins.grey1}`
    }
  },
}));
export default useStyles;
