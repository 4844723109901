const initialState = []

export default function (state = initialState, {type, payload}) {
  switch (type) {
    case "ADD_CLAIM_DASHBOARD_DETAILS":{
        return [{...state, ...payload}];
    }
    default:
      return state;
  }
}