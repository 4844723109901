import { Modal,Box } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { newMapLayerForm } from '../../../../helpers/formData'
import { updateMapLayer } from '../../../../services/apiCalls/mapApi'
import MapLayerForm from '../../newMapLayer/MapLayerForm'
import useStyles from './mapStyle'

const UpdateMapLayer = ({ openModal, closeModal, layerToUpdate, setLayerToUpdate }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleFormChange = (label, event) => {
        setLayerToUpdate({
            ...layerToUpdate,
            [label]: event.target.value
        })
    }

    const handleMapUpdate = () => {
        const data = layerToUpdate;
        updateMapLayer(layerToUpdate?.id, data)
        dispatch({
            type: "UPDATE_LAYER",
            payload: [data]
        })
        closeModal();
        setLayerToUpdate(newMapLayerForm);
    }

    return (
        <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="Update Map Layer"
            aria-describedby="Update Map Layer"   
        >
            <Box className={classes.updateLayerForm}>
                <MapLayerForm
                    formData={layerToUpdate}
                    handleFormChange={handleFormChange}
                    handleFormSubmit={handleMapUpdate}
                    type="updateLayer"
                />
            </Box>
          
        </Modal>
    )
}

export default UpdateMapLayer