const initialState = []

export default function (state = initialState, {type, payload}) {
  switch (type) {
    case "ADD_LAYER":{
        return [...state, ...payload];
    }
    case "UPDATE_LAYER": {
        const layers = state.filter(item => item.id !== payload[0].id);
        return [...layers, ...payload];
    }
    case "REMOVE_LAYER":{
        const remainingLayers = state.filter(item => item.id !== payload[0].id);
        return remainingLayers;
    }
    default:
      return state;
  }
}