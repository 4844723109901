import React, { useState } from 'react'
import useStyles from './style';
import Button from '@mui/material/Button';
import { Box, FormControl, InputLabel, TextField, Select, FilledInput } from "@material-ui/core";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';

const MapLayerForm = ({formData, handleFormChange, handleFormSubmit, type}) => {
    const classes = useStyles();
    const [passwordVisibilty, setPasswordVisibility] = useState(false);

    const handleClickShowPassword = () => {
        setPasswordVisibility(!passwordVisibilty)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.adminUserSelect}>
                <TextField
                    id="outlined-basic" label="Name" variant="outlined"
                    className={classes.adminUserSelectField}
                    value={formData.name}
                    onChange={(e) => handleFormChange("name", e)}
                />
                <TextField
                    id="outlined-basic"
                    label="Category" variant="outlined"
                    className={classes.adminUserSelectField}
                    value={formData.category}
                    onChange={(e) => handleFormChange("category", e)}
                />
                <FormControl variant="outlined" className={classes.adminUserSelectField}>
                    <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
                    <Select
                        native
                        label="Type"
                        inputProps={{
                            name: 'Type',
                            id: 'outlined-age-native-simple',
                        }}
                        value={formData.layerType}
                        onChange={(e) => handleFormChange("layerType", e)}
                    >
                        <option aria-label="None" value="" />
                        <option value={"WMTSLayer"}>WMTSLayer</option>
                        <option value={"FeatureLayer"}>FeatureLayer</option>
                        <option value={"VectorTileLayer"}>VectorTileLayer</option>
                        <option value={"WebTileLayer"}>WebTileLayer</option>
                        <option value={"GraphicsLayer"}>GraphicsLayer</option>
                    </Select>
                </FormControl>
            </Box>
            <Box className={classes.adminUserFormUrlMain}>
                <TextField
                    id="outlined-basic"
                    label="URl link" variant="outlined"
                    className={classes.adminUserFormUrl}
                    value={formData.url}
                    onChange={(e) => handleFormChange("url", e)} />
            </Box>
            <Box>
                <FormControl variant="outlined" className={classes.authenticationType}>
                    <InputLabel htmlFor="outlined-age-native-simple">Authentication Type</InputLabel>
                    <Select
                        native
                        label="Authentication Type"
                        inputProps={{
                            name: 'Authentication Type',
                            id: 'outlined-age-native-simple',
                        }}
                        value={formData.authenticationType}
                        onChange={(e) => handleFormChange("authenticationType", e)}
                    >
                        <option aria-label="None" value="" />
                        <option value={"None"}>None</option>
                        <option value={"Key"}>Key</option>
                        <option value={"JWT"}>JWT</option>
                    </Select>
                </FormControl>
            </Box>
            {formData?.authenticationType === "Key" ?
                <Box className={classes.adminUserSelect}>
                    <TextField
                        id="outlined-basic"
                        label="Key"
                        variant="outlined"
                        className={classes.authenticationType}
                        value={formData.apiKey}
                        onChange={(e) => handleFormChange("apiKey", e)}
                    />
                </Box>
                : null}
            {formData?.authenticationType === "JWT" ? <Box className={classes.adminUserSelect}>
                <TextField
                    id="outlined-basic"
                    label="Username"
                    variant="outlined"
                    lassName={classes.adminUserSelectField}
                    value={formData.userName}
                    onChange={(e) => handleFormChange("userName", e)}
                />
                <FormControl className={clsx(classes.margin, classes.textField, classes.adminUserSelectField, classes.adminUserPass,)} variant="outlined" >
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <FilledInput
                        id="outlined-adornment-password"
                        type={passwordVisibilty ? 'text' : 'password'}
                        value={formData.password}
                        onChange={(e) => handleFormChange("password", e)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {passwordVisibilty ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <TextField
                    id="outlined-basic"
                    label="AuthUrl"
                    variant="outlined"
                    className={classes.adminUserSelectField}
                    value={formData.authUrl}
                    onChange={(e) => handleFormChange("authUrl", e)}
                />
            </Box> : null}
            <Box className={classes.adminUserBtnMain}>
                <Button className={classes.adminUserBtn} sx={{ display: 'block', mt: 2 }} onClick={handleFormSubmit}>
                    {type === "updateLayer" ? "Update Layer" : "Add Layer"}
                </Button>
            </Box>
        </Box>
    )
}

export default MapLayerForm