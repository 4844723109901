import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    dropZone: {
        padding: '16px 0px',
        margin: '20px 32px',
        width:'-webkit-fill-available',
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        border: `2px dashed ${theme.mixins.purple}`,
        width: '99%',
        borderRadius: '8px',
        backgroundColor: '#f4f4f7',
    },
    upload_content: {
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    upload_icon_box: {
        height: '64px',
        width: '64px',
        borderRadius: '50% !important',
        background: `${theme.mixins.lightYellow} !important`,
        display:'flex !important',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '64px',
        margin: '0 48px !important'
    },
    upload_icon:  {
        color: `${theme.mixins.purple}`,
    },
    attachment: {
        display:'flex',
        flexDirection: 'row',
        alignItems:'center',
        fontSize: '16px'
    },
    warning: {
        fontSize: '14px !important',
        color: `${theme.mixins.grey}`,
        '& span':{
            color: `#2f2f5d`,
            fontWeight:'bold',
        },
    },
    fileDetailsWrapper:{
        display:  'flex',
        marginLeft: '48px',
    },
    fileDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        marginLeft: '48px',

        '& p' :{
        fontSize: '14px',
        color: `${theme.mixins.grey}`,
        },
    },
    file_heading : {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '500px',
    },
    uploadFileButton: {
        background: 'none !important',
        fontSize:'16px !important',
        fontWeight: 'bold !important',
        color: `${theme.mixins.red} !important`,
        boxShadow:' none',
        minWidth:'48px !important',
        padding: '0px 8px !important',
        boxShadow :'none !important',
        textTransform: 'none !important',
        '&:hover' :{
            textDecoration: 'none !important',
            background: 'none !important',
            boxShadow: 'none !important'
        }
    },
    attachmentContent:{
        color:'#34346f',
        fontWeight:'600 !important',
    },
}));

export default useStyles;
