import React, { useState, useEffect, Suspense } from "react";
import { Box } from '@material-ui/core';
import clsx from "clsx";
import UseStyles from "./style";
import {
  useRouteMatch,
  Redirect,
  useHistory,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Navigaiton from "../../components/organisms/navigation/navigation";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import Config from "../../config";
import { setNavMenu } from "../../actions/navigationActionCreator";
import HeaderBar from "../../components/organisms/headerBar/headerBar";
// import { logout } from "../../actions/loginActionCreator";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = React.memo((props) => {
  const { Auth, Curated, Navigation } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = UseStyles();
  const navigate = useNavigate();
  // const { params, url } = useMatch();
  const [openNav, setOpenNav] = useState(false);
  const { screen } = useParams();

  useEffect(() => {
    if(screen !== "map viewer"){
      window.localStorage.setItem("layer",JSON.stringify([]))
    }
  },[screen])

  useEffect(() => {
    const menuItems = Config.roles[Auth.user.role];
    dispatch(setNavMenu(menuItems));
  }, []);

  const getScreenTemplate = (slug) => {
    try {
      if (slug) {
        if (Object.keys(Config.screens).indexOf(slug) >= 0) {
          return Config.screens[slug];
        } else {
          throw Error;
        }
      } else {
        const menuItems = Config.roles[Auth.user.role];
        return <Navigate to={`${menuItems[0]}`} />;
      }
    } catch (e) {
      console.log(e);
      if (Auth.isLoggedIn) {
        alert(
          ` Roles cannot be curated, Please check if user has proper access. Logging Out User`
        );
        // dispatch(logout()).then(() => {
        //   return <Navigate to="/login" />;
        // });
        return null;
      }

      return <Navigate to="/" />;
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        closeOnClick
        style={{zIndex:'10000'}}
      />
      <HeaderBar className={classes.headerBar}></HeaderBar>
      <Navigaiton
        className={classes.navigaitonBlock}
        openNav={openNav}
        setOpenNav={setOpenNav}
      />
      <div
        className={clsx(
          classes.infoBoard,
          {
            [classes.infoBoardShift]: Navigation.isOpen,
          },
          classes.screen
        )}
      >
        <Hidden lgUp implementation="css">
          <MenuIcon
            className={classes.hamburger}
            onClick={() => setOpenNav(!openNav)}
          />
        </Hidden>
        <Suspense fallback={<ProgressBar />}>
          {getScreenTemplate(screen)}
        </Suspense>
      </div>
    </>
  );
});

export default Dashboard;
