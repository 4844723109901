import React, { useEffect, useRef, useState } from "react";
import Sketch from "@arcgis/core/widgets/Sketch";
import Measurement from "@arcgis/core/widgets/Measurement";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined';
import StraightenIcon from '@mui/icons-material/Straighten';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

const SketchWidget = ({ view }) => {
    const {MapFilterReducer} = useSelector((state) => state);
    const layer = view?.map.findLayerById('Sketch');
    const [checked, setChecked] = useState(false)
    const sketch = useRef();
    const dispatch = useDispatch();

    const resetData = (field) => {
        const data = Object.keys(MapFilterReducer[0]).filter((key) => !field.includes(key)).reduce((obj, key) => {
          obj[key] = MapFilterReducer[0][key];
          return obj;
        }, {}); 
        return data 
    }

    useEffect(() => {
        sketch.current = new Sketch({
            layer: layer,
            view: view,
            creationMode: "update",
            updateOnGraphicClick: true,
            snappingOptions: {
                enabled: false,
                distance: 5,
                featureSources: [{ layer: layer, enabled: true }],
            },
            visibleElements: {
                snappingControlsElements: {
                    header: true,
                    layerList: false,
                },
                settingsMenu: false,
            }
        })

        sketch.current.on("create", async(event) => {           
            if (event.state === "complete") {
                const data = webMercatorUtils.webMercatorToGeographic(event.graphic.geometry).toJSON();
                const rings = data?.rings;
                let coordinates = [];
                if (rings?.length > 0) {
                    for(var i = 0; i < rings.length; i++){
                        for(var j = 0; j < rings[i].length; j++){
                            coordinates.push({longitude: rings[i][j][0], latitude: rings[i][j][1]})
                        }
                    }  
                }
                else{
                    coordinates.push({longitude: data?.x, latitude: data?.y})
                }  
                let filter;
                if(coordinates.length === 1){
                    filter = {...MapFilterReducer[0],point: {...coordinates[0]}}
                }
                else if(coordinates.length === 0){
                    filter = {}
                }
                else{
                    filter = {...MapFilterReducer[0],polygon: [...coordinates]}
                }
                dispatch({
                    type: "ADD_MAP_FILTER",
                    payload: filter
                })
            }
        });

        const points = document.getElementById("draw-point");
        const polyline = document.getElementById("draw-polyline");
        const polygon = document.getElementById("draw-polygon");
        const circle = document.getElementById("draw-circle");
        const rectangle = document.getElementById("draw-rectangle");
        const undo = document.getElementById("undo");

        points.addEventListener("click", () => {
            drawPoint();
        });
        polyline.addEventListener("click", () => {
            drawPolyLine();
        });
        polygon.addEventListener("click", () => {
            drawPolygon();
        });
        circle.addEventListener("click", () => {
            drawCircle();
        });
        rectangle.addEventListener("click", () => {
            drawRectangle();
        });
        undo.addEventListener("click", () => {
            Undo();
        });
        view?.ui.add(sketch.current, "bottom-right");
        sketch.current.view = view;

        const drawPoint = () => {
            sketch.current.create("point");
        }
        const drawPolyLine = () => {
            sketch.current.create("polyline");
        }
        const drawPolygon = () => {
            sketch.current.create("polygon");
        }
        const drawCircle = () => {
            sketch.current.create("circle");
        }
        const drawRectangle = () => {
            sketch.current.create("rectangle");
        }
        const Undo = () => {
            sketch.current.undo()
        }

        const measurement = new Measurement();

        const distanceButton = document.getElementById("distance");
        const areaButton = document.getElementById("area");
        const clearButton = document.getElementById("clear");

        distanceButton.addEventListener("click", () => {
            distanceMeasurement();
        });
        areaButton.addEventListener("click", () => {
            areaMeasurement();
        });
        clearButton.addEventListener("click", () => {
            clearMeasurements();
        });

        view?.ui.add(measurement, "bottom-right");
        measurement.view = view;

        //DistanceMeasurement2D
        const distanceMeasurement = () => {
            const type = view?.type;
            measurement.activeTool = "distance";
            distanceButton.classList.add("active");
            areaButton.classList.remove("active");
        }

        // AreaMeasurement2D
        const areaMeasurement = () => {
            measurement.activeTool = "area";
            distanceButton.classList.remove("active");
            areaButton.classList.add("active");
        }

        // Clears all measurements
        const clearMeasurements = () => {
            distanceButton.classList.remove("active");
            areaButton.classList.remove("active");
            measurement.clear();
            layer.removeAll();
            const envelop = JSON.parse(window.localStorage.getItem('envelop')); 
            const reset = resetData(['point','polygon']);
            dispatch({
                type: "ADD_MAP_FILTER",
                payload: envelop ? {...reset,envelop} : {...reset}
            })
        }
    }, []);

    const Snapping = () => {
        const toggle = !checked;
        setChecked(toggle);
        sketch.current = new Sketch({
            layer: layer,
            view: view,
            creationMode: "update",
            snappingOptions: {
                enabled: toggle ? true : false,
                distance: 5,
                featureSources: [{ layer: layer, enabled: true }],
            },
            visibleElements: {
                snappingControlsElements: {
                    header: true,
                    layerList: false,
                },
                settingsMenu: false,
            }
        })
    }

    return (
        <>
            <div id="toolbarDiv" className="sidebarselectarea-popup">
                <p>draw and measure</p>
                <div className="selectarea-top esri-component esri-widget">
                    <div className="selectarea-top-inner">
                        <button className="dot"  id="draw-point"><CircleIcon /></button>
                        <button id="draw-polyline"><ShowChartOutlinedIcon /></button>
                        <button id="draw-circle"><CircleOutlinedIcon /></button>
                        <button id="draw-rectangle"><RectangleOutlinedIcon /></button>
                        <button id="draw-polygon"><PentagonOutlinedIcon /></button>
                        <button id="distance"><StraightenIcon /></button>
                        <button id="area"><ControlCameraIcon /></button>
                    </div>
                </div>
                <div className="selectarea-snapping">
                    <button className="selectarea-chk-btn" onClick={Snapping}>{checked ? <Box className="selectarea-chkin"> <CheckIcon /> </Box>: " "}</button><span>Snap enabeled</span>
                    <button id="snapping" className="esri-widget--button esri-interactive esri-icon-measure-line" title="Sketch Tool">Snapping</button>
                </div>
                <div className="selectarea-clear">
                    <button id="undo" className="esri-widget--button esri-interactive esri-icon-measure-line" title="Sketch Tool" >Undo</button>
                    <button id="clear" className="esri-widget--button esri-interactive esri-icon-trash" title="Clear Measurements">Clear</button>
                </div>
            </div>
        </>
    );
};

export default SketchWidget;