import { makeStyles } from "@material-ui/core/styles";
import { padding } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  detailsHeader: {
    width: "100%",
    padding: "18px 0",
    background: `${theme.mixins.lightYellow}`,
    borderRadius: "8px 8px 0px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  listHeading: {
    width: "100%",
    color: `${theme.mixins.purple}`,
  },
  editIcon: {
    position: "absolute",
    right: "0",
    top: "6px",
    padding: "18px",
    cursor: "pointer",
  },
  pdfIcon: {
    cursor: "pointer",
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "85%",
    padding: "0px",
    borderRadius: "8px 8px 0px 0px",
    boxShadow: "0px 0px 36px -20px black",
  },
  listItem: {
    listStyle: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "16px  0px",
  },
  item: {
    width: "50%",
    paddingLeft: "64px",
    color: `${theme.mixins.purple}`,
    fontWeight: "bold",
  },
  objectItem: {
    width: "50%",
    display: "flex",
  },
  objectItemLabel: {
    paddingLeft: "32px !important",
  },
  objectItemValue: {
    paddingLeft: "4px",
  },
  item1: {
    width: "50%",
    paddingLeft: "64px",
    color: `${theme.mixins.grey3}`,
    // fontWeight: 'bold'
  },
  "@global": {
    "li:nth-of-type(even)": {
      // display: 'block !important',
      backgroundColor: `${theme.mixins.grey2}`,
    },
    "li:nth-of-type(odd)": {
      backgroundColor: "#FFF",
    },
  },

  fileEditState: {
    width: "50%",
    color: `${theme.mixins.grey3}`,
    display: "flex",
    justifyContent: "space-between",
    marginRight: "64px",
  },

  inputEditState: {
    width: "50%",
    marginRight: "64px",
  },

  uploadBtn: {
    height: "36px",
    width: "160px",
    background: `${theme.mixins.white}`,
    borderRadius: "4px",
    fontSize: "14px",
    color: `${theme.mixins.lightBlue} !important`,
    border: `1px solid ${theme.mixins.lightBlue} !important`,
  },

  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    marginTop: "64px",
    marginBottom: "24px",
  },

  actionBtn: {
    height: "56px",
    width: "140px",
    background: `${theme.mixins.white}`,
    borderRadius: "8px",
    fontSize: "16px",
    color: `${theme.mixins.purple} !important`,
    border: `1px solid ${theme.mixins.purple} !important`,

    "&:hover": {
      background: `${theme.mixins.purple}`,
      color: `${theme.mixins.white} !important`,
    },
  },

  claimStatusStepper: {
    backgroundColor: "#ffffff",
    padding: "50px 0px",

    "& svg": {
      width: "2em !important",
      height: "2em !important",
      zIndex: "999",
    },
    "& .MuiStepConnector-horizontal": {
      top: "24px !important",
    },

    "& .MuiStepConnector-line": {
      borderColor: "#E8E9F3",
      borderTopWidth: "2px",
    },
    "& .MuiStepIcon-root.Mui-completed": {
      color: "#34346f",
    },
    "& .Mui-disabled": {
      "& svg": {
        color: "#fff",
        border: " 2px solid #dedfea",
        borderRadius: "50%",
      },
      "& .MuiStepIcon-text": {
        fill: "#34346f",
      },
    },

    "& .MuiStepIcon-root.Mui-active": {
      color: "#34346f",
    },
  },
}));

export default useStyles;
