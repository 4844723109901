import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "545px",

    "& .MuiCard-root": {
      padding: "50px",
      display: "flex",
      flexDirection: "column",
      gap: "30px",
      borderRadius: "20px",

      "& .MuiButton-contained": {
        backgroundColor: "#34346F",
        color: "#fff",
      },
    },
  },
  heading: {
    fontSize: "1.75rem",
    color: "#34346F",
    fontWeight: "bold",
    marginBottom: "6px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "12px",
    marginTop: "-15px",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  signup_Action: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  checkbox: {
    padding: "8px 8px 8px 0px",
  },
  button: {
    width: "100%",
    height: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
  },
  login: {
    fontSize: "20px",
  },
  imgIcon: {
    marginRight: "16px",
  },
  social_login: {
    fontSize: "14px",
    backgroundColor: "#fff !important",
    color: "#34346F !important",
    border: "1px solid #34346F !important",
  },
  lineBreaker: {
    width: "100%",
    textAlign: "center",
    borderBottom: `1px solid #eee`,
    lineHeight: "0.1em",
    margin: "0",
    "& span": {
      background: "#fff",
      padding: "0 10px",
      fontSize: "12px",
      color: `${theme.mixins.grey}`,
    },
  },
  loginFooter: {
    textAlign: "center",
    fontSize: "16px",
    "& p": {
      color: "grey",
    },
    "& a": {
      color: "#34346F",
    },
  },

  errorMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    color: "red",
    marginTop: "10px",
    fontWeight: "bold",
  },
}));

export default useStyles;
