import { makeStyles } from "@material-ui/core/styles";
import BackArrowIcon from "../../../assets/icons/backArrow.svg";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  upload_container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  button_row: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
  },
  upload_file_button: {
    height: "36px",
    width: "160px",
    marginTop:"-15px !important",
    background: `${theme.mixins.white} !important`,
    borderRadius: "4px",
    fontSize: "14px",
    color: `${theme.mixins.lightBlue} !important`,
    border: `1px solid ${theme.mixins.lightBlue} !important`,
    
    "&:hover": {
      background: `${theme.mixins.lightBlue} !important`,
      color: `${theme.mixins.white} !important`,
    },
    '@media (max-width:1680px)' :{
      marginRight:'20px !important',
     },
  },
  claimBack: {
    position: "relative",
    "&:hover": {
      backgroundColor: "#ffb96600 !important",
      "&::before": {
        marginLeft: "0px",
      },
    },

    "&::before": {
      border: "0",
      content: "''",
      position: "absolute",
      width: "16px",
      height: "13px",
      top: "12px",
      left: "12px",
      background: `url(${BackArrowIcon}) no-repeat`,
      marginLeft: "7px",
      transition: ".4s",
      "& :hover": {
        marginLeft: "0px",
      },
    },
    "& span": {
      color: "#32325f",
      textTransform: "capitalize",
      marginLeft: "35px",
    },
  },
  userHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    '& button': {
        border: '1px solid #34346f',
        borderRadius: '6px',
        color: '#34346f;',
        fontSize: '14px',
        padding: '6px 20px',
        fontWeight:'normal',
        textTransform:'capitalize',
        '&:hover': {
            border: '1px solid #34346f',
            color: '#34346f',
        },
        '& img': {
         marginRight:'15px',
        }
    }
  },
  customTableParents:{
    width: "100%",
   
  
    '& th':{
      '&:nth-child(1)': {
        '@media (max-width:1680px)' :{
          width:'100px',
        },
      },
      '&:nth-child(2)': {
        '@media (max-width:1680px)' :{
          width:'125px',
        },
      },
      '&:nth-child(3)': {
        '@media (max-width:1680px)' :{
          width:'100px',
        },
      },
      '&:nth-child(4)': {
        '@media (max-width:1680px)' :{
          width:'100px',
        },
      },
      '&:nth-child(5)': {
        '@media (max-width:1680px)' :{
          width:'100px',
        },
      },
      '&:nth-child(6)': {
        width:'400px',
        '@media (max-width:1680px)' :{
          width:'300px',
        },
       
      },
      '&:nth-child(7)': {
        width:'125px',
        '@media (max-width:1680px)' :{
          width:'125px',
        },
      },
  },
 
  },
}));

export default useStyles;
