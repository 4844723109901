import { combineReducers } from "redux";
import Auth from "./authReducer";
import Navigation from "./navigationReducer";
import MapViewer from "./mapReducer";
import FilterReducer from "./filterReducer";
import LayerReducer from "./mapLayerReducer";
import UserReducer from "./userReducer";
import ClaimDetailReducer from "./claimDetailsReducer";
import ClaimDashboardReducer from "./claimDashboardReducer";
import MapFilterReducer from "./mapFilterReducer";

const reducer = combineReducers({
  Auth,
  Navigation,
  MapViewer,
  FilterReducer,
  MapFilterReducer,
  LayerReducer,
  UserReducer,
  ClaimDetailReducer,
  ClaimDashboardReducer
});
export default reducer;
