import { makeStyles } from "@material-ui/core/styles";
import BackgroundImage from "../../assets/icons/left-circle.svg";

const useStyles = makeStyles((theme) => ({
    headerErrorBar: {
        background: "#2F2F5D",
        zIndex: "9999",
        padding: "5px 20px",
        display: "flex",
        alignItems: "center",
        position: 'sticky',
        top: '0',
        height: '69px',
    },
    headerErrorBarInner: {
        margin: '0 auto',
        width: '100vw',
        height: '100vh',
        padding: '0 30px',
        maxWidth: 'calc(100% - 100px)',
        '& h2': {
            fontWeight: 'Bold',
            fontSize: '89px',
            color: '#34346F',
        },
        '& p': {
            fontSize: '21px',
            color: '#34346F',
        },

    },
    ErrorImg: {
        width: '100%'
    },
    headerErrorButton: {
        backgroundColor: '#34346F',
        marginTop: '20px',
        borderRadius: '20px',
        boxShadow: 'none',
        border: '1px solid #34346F',
        width: '210px',
        height: '40px',
        cursor: 'pointer',

        '& label': {
            fontSize: '16px',
            textTransform: 'capitalize',
            display: 'none',
            cursor: 'pointer',
        },
        '& p': {
            fontSize: '16px',
            textTransform: 'capitalize',
            display: 'block',
            color: '#fff',
            margin: '0',
            cursor: 'pointer',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: '#34346F',
            cursor: 'pointer',
            '& p': {
                display: 'none',
            },
            '& label': {
                display: 'block',
            },
        },
    },
    headerErrorBarBg: {
       
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height:'90vh',
       
    },
    ErrorBarBg:{

    },
    headerErrorBarBgpadding:{
        background: `url(${BackgroundImage}) no-repeat`,
        backgroundSize:'cover',
    },
    ErrorImgContainer: {
        paddingRight: '100px',
        height: '92vh',
        display:'flex',
        alignItems:'flex-end'
    },
    headerErrorBarInnerContainer: {
        height: 'calc(100vh - 100px)',
    }

}));

export default useStyles;