import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import useStyles from './style';

const steps = [
    'Report Received',
    'Loss Adjuster',
    'Claim Management',
    'Report Completed',
];

const ClaimStatus = () => {

    const classes = useStyles();
    return (
        <Box sx={{ width: '100%' }} className={classes.claimStatusStepper}>
            <Stepper activeStep={1} alternativeLabel >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}

export default ClaimStatus





