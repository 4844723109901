import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "545px",
    
    "& .MuiCard-root": {
      padding: "50px",
      display: "flex",
      flexDirection: "column",
      gap: "30px",
      borderRadius: '20px',

      "& .MuiButton-contained": {
        backgroundColor: "#34346F",
        color: "#fff",
      },
    },
  },
  heading: {
    fontSize: "1.75rem",
    color: "#34346F",
    fontWeight: "bold",
    marginBottom: '6px'
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "12px",
    marginTop: '-15px'
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    width:'100%',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none'
  },
  login: {
    fontSize: '20px'
  },

}));

export default useStyles;
