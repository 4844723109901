import moment from "moment";

const handleStartTime = (date)=>{
    const getDate = new Date(date)
    const newDate = new Date(getDate.setDate(getDate.getDate() + 1));
    let start = moment(newDate).utcOffset(0);
    start.set({hour:0,minute:0,second:0,millisecond:0})
    start.utc();
    return start.format();
};

const handleEndTime = (date) =>{
    const getDate = new Date(date)
    const newDate = new Date(getDate.setDate(getDate.getDate() + 1));
    let end = moment(newDate).utcOffset(0);
    end.set({hour:23,minute:59,second:59,millisecond:0})
    end.utc();
    return end.format();
};

export {handleStartTime, handleEndTime};