import { makeStyles } from "@material-ui/core/styles";
import { CenterFocusStrong } from "@material-ui/icons";

const drawerWidth = 325;
const useStyles = makeStyles((theme) => ({

  dividedSidebar: {
    display:'flex',
    justifyContent:'space-between',
    flexDirection:'column',
    height: '100vh',
  },

  menuButton: {
    position: "absolute",
    right: "-20px",
    bottom: "35%",
    zIndex: "99",
    padding: "0px 4px 0px 0px",
    height: "104px",
    width: "22px",
    background: "url(/images/sidebar-arrow.svg) no-repeat  !important",
    borderRadius: "0px",
  },
  navigationWrapper: {
    width: "auto",
    position: "fixed",
    left: 0,
    height: "100%",
    background: "transparent",
    overflow: "visible",
    zIndex: "99",
    // marginTop: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },

  collapseDrwInputList: {
    width: "100%",
    margin: "0 auto",
    paddingTop: "3px",
    paddingBottom: "3px",
    "& span": {
      textAlign: "left !important",
      fontSize: "14px",
      fontWeight: "700",
      color: "#fff !important",
    },
    // "& .MuiListItemButton-root:hover": {
    //   paddingLeft: '32px !important',
    // },
  },
  collapseDrwInputListNew: {
    width: "100%",
    margin: "0 auto", 
    paddingTop: "10px",
    paddingBottom: "0px",
    

    "& span": {
      textAlign: "left !important",
      fontSize: "13px",
      fontWeight: "500",
      color: "#fff !important",
      marginLeft: "0px",
      // paddingRight: '0px',
      // paddingLeft: '0px !important',
      padding:'0px !important',
    },
    // "& .MuiListItemButton-root:hover": {
    //   paddingLeft: '32px !important',

    // },
  },

  mapsideBarListItemIcon: {
    marginRight: "10px",
  },
  collapseDrwInput: {
    minWidth: "25px",
    color: "#fff",
    marginRight: "0px",
    "& span:hover": {
      background: "transparent",
    },
    "& span": {
      "& svg": {
        width: '0.9em',
        height: '0.9em',
      },
    },
  },

  navButton: {
    padding: "14px 16px 14px 15px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "18px",
    },
    "&.Mui-selected": {
      background: "#fff",
      "& .MuiListItemIcon-root": {
        "& svg": {
          fill: "#2F2F5D !important",
        },
      },
      "& .MuiListItemText-root": {
        "& span": {
          color: "#2F2F5D",
        },
      },
    },

    "&.Mui-selected::before": {
      content: "''",
      position: "absolute",
      top: "0",
      height: "100%",
      left: "0",
      width: "5px",
      background: "#F4BC73",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },

    "&:hover": {
      background: "#fff !important",
      position: "relative",
      "&::before": {
        content: "''",
        position: "absolute",
        top: "0",
        height: "100%",
        left: "0",
        width: "5px",
        background: "#F4BC73",
      },
      "& .MuiListItemIcon-root": {
        "& svg": {
          fill: "#2F2F5D !important",
        },
      },
      "& .MuiListItemText-root": {
        "& span": {
          color: "#2F2F5D",
        },
      },

      "& .MuiListItemIcon-root": {
        "& svg": {
          fill: "#2f2f5d  !important",
          // transform: "scale(1.25)",
        },
      },
    },
  },
  icon: {
    fill: "#fff !important",
  },
  actionIcon: {
    fill: "#ced4da !important",
  },
  actionPopUp: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& svg": {
      margin: "0 10px",
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#34346F",
    marginTop: "70px",
    border:"0px !important",
    height: 'calc(-70px + 100vh)',

    '@media (max-width:1680px)' :{
      width: '300px',
    },
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 19,
    },
    background: "#34346F",
    marginTop: "70px",
    border:"0px !important",
    height: 'calc(-70px + 100vh)',
  },
  logo: {
    width: "100%",
    maxWidth: "180px",
    display: "block",
    margin: "15px auto 0",
    padding: "0 10px",
    boxSizing: "border-box",
  },
  mobilelogo: {
    maxWidth: "120px",
    display: "block",
    margin: "15px auto 0",
    padding: "0 10px",
    boxSizing: "border-box",
    height: "50px",
    width: "50px",
  },
  logoContainer: {
    marginBottom: "25px",
    marginTop: "15px",
  },
  arrowButton: {
    position: "relative",
    marginLeft: "0px",
    borderRadius: "0px",
    marginTop: "-5px",
  },

  navText: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#fff",
    lineHeight: "1.13",
  },
  nav: {
    transition: "opacity .3s ease-in-out",
    "& span": {
      paddingLeft: "5px",
    },
  },
  hideNav: {
    transition: "opacity .3s ease-in-out",
    opacity: 0,
    display: "none",
  },
  link: {
    textDecoration: "none",
    display: "block",
  },
  collapseDrw: {
    borderLeft: "5px solid  transparent !important",

    "&:hover": {
      background: "#fff",
      borderLeft: "5px solid #F4BC73 !important",
      "& div": {
        background: "#fff",
      },
      "& span": {
        color: "#2f2f5d !important",
      },
      "& svg": {
        fill: "#2f2f5d !important",
      },
    },
    "& .Mui-selected": {
      background: "#fff",
      position: "relative",
    },
    "& .Mui-selected::before": {
      top: "0",
      left: "0",
      width: "5px",
      height: "100%",
      content: "",
      position: "absolute",
      background: "#F4BC73",
    },
  },

  mapsideBarListItem: {
    paddingLeft: "10px",
    "& ul": {
      listStyle: "none",
    },
    "& li": {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 25px 10px 0px",
      alignItems: "center",
      "& p": {
        fontWeight: "bold",
        fontSize: "14px",
        color: "#fff",
        lineHeight: "16px",
        padding: "0px",
        margin: "0px",
        position: "relative",
      },
    },
    '& .MuiListItemButton-root': {

      '& .MuiListItemText-root span': {
        fontWeight: "700",
        fontSize: "14px",
      },
    },
  },

  mapSubmenuListItem: {
    // background:"green",
    margin: "0px",
    "& .MuiListItemButton-root":{
      padding: "10px 0px 10px 9px",
    },
    "& span":{
      fontWeight:'500 !important',
      fontSize: '12px !important',
    },
  },

  collapseDrwMap: {
    padding: "14px 16px 14px 12px !important",
  },
  // navselect: {
  //   marginLeft: "36px"
  // },
  locationDrw: {
    minWidth: "30px",
  },
  navDrawer: {
    "& .MuiPaper-root": {
      width: "200px",
    },
  },
  logOutDrw:{
    marginLeft:'10px',
  },
  leftSpacing: {
    paddingTop: "0px",
  },
  bottomSidebar:{
    borderTop:'1px solid #4a4a83',
  },
  layerBox: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "space-between !important"
  },
  labelBox:{
    display: "flex",
    alignItems: "center"
  }
}));

export default useStyles;
